@import "../../../styles/var.scss";

.modal-footer {
  padding: 24px 24px 34px;
  margin-top: auto;
  display: flex;
  gap: 16px;
  background-color: $sourCream;

  @media (min-width: $tablet) {
    padding: 24px 32px;
  }

  &--sticky {
    margin-top: -1px;
    position: sticky;
    bottom: 0;
    z-index: 1;
    border-top: 1px solid $eel;

    @media screen and (min-width: $tablet) {
      margin: 0;
      border: initial;
      position: relative;
    }
  }

  // &--timeline {
  //   border-top: 1px solid $eel;

  //   @media (min-width: $tablet) {
  //     display: none;
  //   }
  // }

  // &--border {
  //   border-top: 1px solid $eel;
  // }
}