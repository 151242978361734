@import "../../../../styles/var";

.timeline-entry {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__log-date {
    font-size: 11px;
    font-weight: 600;
    line-height: 13.2px;
    letter-spacing: 0.02em;
    color: $text;
    opacity: 0.85;
    margin-top: 8px;
  }

  &__log-note {
    margin: 0;
    padding: 8px 16px;
    background-color: $garlic;
    border-radius: 8px;

    font-size: 15px;
    font-weight: 500;
    line-height: 21.75px;
    color: $text;
  }
}
