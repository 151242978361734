@import "../../../styles/var";

.show-only--mobile-only {
  @media screen and (min-width: $tablet) {
    display: none !important;
  }
}

#job-history {
    background: $sourCream;
    border-radius: 5px;
    border: 1px solid $haddock;
    padding: 32px;
    margin-bottom: 32px;

    //.CompleteModal {
    //    background: white;
    //    display: flex;
    //    flex-direction: column;
    //    border: none;
    //    padding: 35px;
    //    width: 400px;
    //
    //    border-radius: 20px;
    //    box-shadow: 4px 4px 50px rgba(47, 38, 33, 0.05);
    //}
    //
    //.CompleteModal {
    //    .header {
    //        font-weight: 600;
    //        font-size: 18px;
    //        line-height: 22px;
    //        display: flex;
    //        align-items: center;
    //        letter-spacing: 0.05em;
    //        text-transform: capitalize;
    //
    //        color: #252525;
    //        margin-bottom: 10px;
    //    }
    //
    //    textarea {
    //        background: #fcfcfc;
    //        border-radius: 10px;
    //        border: none;
    //        height: 230px;
    //        padding: 20px;
    //        margin-bottom: 20px;
    //    }
    //}

    .header-row {
        display: flex;
        width: 100%;
        height: 60px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        .show-only {
            gap: 8px;

            :first-child {
                margin-right: 8px;
                color: $truffle;
            }

            button {
                background: $sourCream;
                border: 1px solid $haddock;
                height: 60px;
                padding: 0 16px;
                border-radius: 5px;
                margin-left: 10px;

                color: $truffle;
                font-weight: 500;

                &.selected {
                    background: $bubblegum;
                    color: $fmoRed;
                    border-color: $sorbet;
                    font-weight: 600;
                }
            }
        }

        input {
            border-radius: 10px;
            height: 44px;
            width: 300px;
            border: none;
            padding-left: 60px;
            margin-left: 40px;
            margin-top: 10px;
        }
    }

    .actions-wrapper {
        height: 260px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 24px;
        border: 1px solid $haddock;
        border-radius: 5px;
        box-sizing: border-box;

        h3 {
            font-size: 20px;
        }

        p {
            font-size: 15px;
            line-height: 150%;
            color: $soySauce;
            margin: 0;
            font-weight: 400;
        }

        .action-buttons {
            margin-top: auto;
            display: flex;
            gap: 16px;
        }

        .action-button {
            width: fit-content;
            height: 100%;
            min-height: 46px;
                        padding: 0 32px;

            -webkit-animation: opacity 0.2s ease-in-out;
            -moz-animation: opacity 0.2s ease-in-out;
            -o-animation: opacity 0.2s ease-in-out;
            animation: opacity 0.2s ease-in-out;

            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: center;
            justify-content: center;
            border-radius: 5px;

            font-weight: 600 !important;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.02em;
            cursor: pointer;
        }

        .ov-create-job {
            background-color: $purplePale;
            border: 1px solid $purpleMiddle;
            color: $purple;
        }

        .ov-create-log {
            background-color: $bubblegum;
            border: 1px solid $sorbet;
            color: $fmo;
        }

        .icon {
          border-radius: 100%;
          border-color: $eel;
          box-sizing: border-box;
        }
    }


    .job-cards {
        display: grid;
        gap: 12px;
        animation: opacity 0.2s ease-in;

        @media screen and (min-width: $tablet) {
          gap: 16px;
        }

        @media screen and (min-width: 900px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media screen and (min-width: 1350px) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @media (min-width: 1600px) {
          grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
        }

        .job-card {
            background-color: $purplePale;
            border: 1px solid $purpleMiddle;
        }

        .job-card,
        .log-card, 
        .marketing-card {
            animation: opacity 0.2s ease-in;
            border-radius: 5px;
            padding: 16px;

            display: flex;
            flex-direction: column;
            gap: 16px;

            min-height: 260px;

            .job-header {
                display: flex;
                flex-direction: row;
                gap: 8px;
            }

            .job-status, 
            .job-date {
                box-sizing: border-box;
                border-radius: 5px;

                display: flex;
                align-items: center;
                width: fit-content;
                height: 32px;
                padding: 0 8px;

                font-weight: 600;
                font-size: 13px;
                letter-spacing: 0.01em;
                text-align: center;
                text-transform: capitalize;
            }

            .pending {
                background-color: $bubblegum !important;
                border: 1px solid $pancetta;
                color: $fmoRed;
            }

            .done {
                background-color: $garlic !important;
                border: 1px solid $haddock;
                color: $thali02;
            }

            .job-date {
                background-color: $sourCream;
                border: 1px solid $haddock;
            }

            .job-title {
                color: $purple;
                font-weight: 600;
                font-size: 16px;
                text-transform: capitalize;
            }

            .job-created-by {
                color: $thali02;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                margin-top: -8px;
                letter-spacing: 0.005em;
                
                .username {
                    text-transform: capitalize;
                    color: $thali02;
                    font-weight: 600;
                }
            }

            .job-content {
                    font-weight: 450;
                    font-size: 15px;
                    line-height: 150%;
                    color: $soySauce;

                    flex-grow: 1;
                    overflow: hidden;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    padding-right: 4px;
            }

            button {
                border: none;
                background-color: $purpleMiddle;
                box-sizing: border-box;
                border-radius: 5px;
                color: $purple;
                width: 100%;
                font-weight: 600;
                font-size: 15px;
                height: 46px;
            }
        }

        .log-card {
            background: $garlic;
            border: 1px solid $haddock;

            .job-status {
                color: $sourCream;
                background: $truffle;
            }

            .job-title {
                color: $soySauce;
            }
        }

        .marketing-card {
            background-color: $butter;

            .job-status {
                background-color: $banoffee;
                color: $truffle;
            }

            .job-date {
                background-color: $cremeBrulee;
                border: none;
                color: $truffle;
            }

            .job-title {
                color: $soySauce;
                font-weight: 600;
            }

            .job-created-by {
                color: $truffle;
            }
            
            .job-content {
                color: $truffle;
                font-weight: 500;
            }
        }
    }
}

.timeline {
    display: flex;
    flex-direction: column;

    .timeline-item {
        display: flex;
        flex-direction: column;

        .created {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;

            -webkit-animation: opacity 0.2s ease-in-out;
            -moz-animation: opacity 0.2s ease-in-out;
            -o-animation: opacity 0.2s ease-in-out;
            animation: opacity 0.2s ease-in-out;

            display: flex;
            align-items: center;

            color: #424242;

            &::before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                -moz-border-radius: 10px;
                -webkit-border-radius: 10px;
                border-radius: 10px;
                background-color: $pink;
                margin-right: 10px;
                box-sizing: border-box;
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }

        .text {
            display: flex;
            align-items: center;
            position: relative;
            
            -webkit-animation: opacity 0.2s ease-in-out;
            -moz-animation: opacity 0.2s ease-in-out;
            -o-animation: opacity 0.2s ease-in-out;
            animation: opacity 0.2s ease-in-out;
        }

        .first::before {
            background: white;
            border: 1px solid $purple;
        }

        .last {
            color: $purple;
            margin-left: 0;
            margin-bottom: 0;
            &::before {
                background: $purple;
            }
        }
    }
}

.modalButtons {
    display: flex;
    flex-direction: column;

    :first-child {
        height: 60px;
        background: $pink;
        border-radius: 10px;
        color: $fmoRed;
        border: none;
        margin-bottom: 10px;

        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
    }

    :last-child {
        height: 60px;
        background: $purplePale;
        border-radius: 10px;
        border: none;
        color: $purple;

        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
    }
}

@media (min-width: 1600px) {
    .job-card, 
    .log-card, 
    .marketing-card {
        min-height: 260px !important;
    }
}

@media (max-width: 990px) and
(min-width: 768px) {
    .header-row {
        height: 46px !important;

        .title {
            font-size: 32px !important;
            line-height: 46px !important;
            height: 46px !important;
        }

        .show-only, 
        .show-only button {
            height: 46px !important;
        }
    }

    .job-cards {
        .job-card, 
        .log-card, 
        .marketing-card {
            height: 256px !important;
        }
    }


}

@media (max-width: 768px) {

    #job-history {
        box-sizing: border-box;
        padding: 16px;
        background-color: $sourCream;
        border: 1px solid $haddock;
        border-radius: 0;

        .header-row {
            height: fit-content !important;
            width: min-content !important;
            border-radius: 5px;
            flex-direction: column;
            justify-content: flex-start;
            padding-left: 0;
            padding-left: 0 !important;
            margin-bottom: 8px !important;

            .title {
                font-size: 24px !important;
                height: 32px !important;
                line-height: 32px;
            }
        }

        .show-only {
            flex-wrap: nowrap;
            margin: 0 -24px 8px;
            padding: 0 24px;
            overflow: scroll;
            box-sizing: border-box;
            -ms-overflow-style: none;
            scrollbar-width: none;
            align-self: flex-start;
            justify-content: flex-start;
            gap: 8px;
            max-width: 100vw;
            display: flex;

            &::-webkit-scrollbar {
                display: none;
            }

            .label {
                padding-left: 2px;
                display: none;
            }

            &--full-size {
              display: none;
            }
        }

        .actions-wrapper {
            height: auto;
            padding: 0;
            border: none;
            margin-bottom: 12px;

            .action-buttons {
                gap: 12px;
                flex-direction: column-reverse;

                & > * {
                  flex-basis: auto;
                }
            }

            .action-button {
                display: flex;
                font-size: 15px;
                width: 100%;
                box-sizing: border-box;
            }
            
            .section-title {
                display: none;
            }
        }

        
        .log-card {
            min-height: min-content !important;

            button {
                min-height: 46px !important;
            }
        }
    }
}

.highlight {
  font-weight: 600;
}
