@import "../../styles/var";

.inventory-manager {
  @media screen and (min-width: 768px) {
    position: absolute;
    top: 80px !important;
    left: 110px !important;
    right: 0;
    bottom: 0;
    height: fit-content;
  }

  &__inner {
    padding: 96px 16px 32px;

    @media screen and (min-width: 768px) {
      padding: 24px 24px 32px 8px;
    }
  
    &--device {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
  
      @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  &__section {
    padding: 16px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #F0EFEF;

    @media screen and (min-width: 768px) {
      padding: 32px;
    }
  }

  &__header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__icon {
    height: 24px;
    width: 24px;
    padding: 8px;
    border: 1px solid #E1DFDF;
    border-radius: 100%;
    background: $garlic;
  }

  &__title {
    margin: 0 auto 0 16px;
    font-weight: 700;
    font-size: 26px;
    color: $soySauce;

    @media screen and (min-width: 768px) {
      margin-right: 8px;
    }
  }

  &__serial-number {
    margin: 8px 0 0;
    font-size: 20px;
    font-weight: 500;
    color: $thali02;

    @media screen and (min-width: 768px) {
      margin: 0;
    }
  }

  &__device-type {
    width: fit-content;
    height: 24px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    background: $sorbet;
    font-weight: 600;
    font-size: 11px;
    color: $fmoRed;
    border-radius: 5px;
  }
}