@import "../../styles/var";

$tableGutter: 8px;

.pipeline {
  position: absolute;
  background-color: $feta;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  @media screen and (min-width: 768px) {
    padding: 0;
    left: 110px;
  }

  &__search-wrapper {
    width: 100%;
    height: 56px;
    padding: 0 16px 16px;
    position: fixed;
    top: 72px;
    z-index: 400;
    background-color: $sourCream;
    border-bottom: 1px solid $eel;
    box-sizing: border-box;
  }

  &__filter-button-wrapper {
    width: 100%;
    padding: 16px;
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    position: fixed;
    background-color: $feta;
    box-sizing: border-box;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (min-width: $tablet) {
      width: auto;
      margin: 0;
      padding: 24px 16px 24px 0;
      left: 110px;
      right: 0;
    }
  }

  &__title {
    margin: 0 16px;
    padding: 0 0 0 4px;
    position: sticky;
    left: 16px;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: $soySauce;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}

.pipeline-table {
  width: 100%;
  padding: 0 16px;
  zoom: 100%; // remove
  display: table;
  border-spacing: 0px;

  @media screen and (min-width: 768px) {
    padding: 0 16px 24px 0;
  }

  &__wrapper {
    padding: 24px 0 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (min-width: 768px) {
      padding: 0;
    }
  }

  &__head {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: $feta;
  }

  &__cell-wrapper {
    &-matrix_value {
      min-width: 80px;
    }

    &-source {
      min-width: 104px;
    }

    &-interactions, &-updated_at {
      min-width: 120px;
    }

    &-stage, &-assignee_first_name {
      min-width: 144px;
    }

    &-status_name {
      @media screen and (min-width: $tablet) {
        min-width: 144px;
      }
    }

    &-combined {
      min-width: 250px;

      @media screen and (min-width: 768px) {
        min-width: 360px;
      }
    }
  }

  &__cell {
    height: 56px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $eel;
    border-radius: 5px;
    white-space: nowrap;
    box-sizing: border-box;
    background-color: $sourCream;

    font-size: 15px;
    font-weight: 500;
    line-height: 20.25px;
    color: $soySauce;

    .pipeline-table__row:hover & {
      border-color: $thali01;
    }

    &--header {
      background-color: $garlic;
      font-weight: 600;
      cursor: pointer;
      color: $truffle;
    }

    &--outcome {
      padding: 0 12px;

      @media screen and (min-width: $tablet) {
        padding: 0 16px;
      }
    }

    &--combined {
      justify-content: space-between;
      position: absolute;
      top: 0;
      right: $tableGutter;
      left: 0;
    }

    &--disabled {
      color: $thali01;
      border-color: $haddock;

      .pipeline-table__row:hover & {
        border-color: $eel;
      }
    }
  }

  &__shop-name {
    margin-right: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__row {
    position: sticky;
    cursor: pointer;

    &--rejected:after {
      content: "";
      height: 56px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: repeating-linear-gradient(-45deg, #FBB7B6, #FBB7B6 10px, #FDE2E2 10px, #FDE2E2 20px);
      opacity: 0.2;
      border-radius: 5px;
    }
  }

  td, th {
    position: relative;
    padding: 0 $tableGutter $tableGutter 0;

    &:last-child {
      padding-right: 0;
    }
  }
}