@import "../../styles/var";

.rank-tag {
  height: 24px;
  padding: 0 8px;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  border: 1px solid $haddock;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;

  font-weight: 600;
  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: 0.02em;
  color: $truffle;
  text-transform: capitalize;

  &--large {
    height: 32px;

    font-size: 14px;
    line-height: 19.6px;
    letter-spacing: 0.01em;
  }

  &--square {
    width: 24px;
    padding: 0;

    &.rank-tag--large {
      width: 32px;
    }
  }

  &--pill {
    height: 40px;

    font-size: 15px;
    line-height: 20.25px;
  }

  &--new-shop {
    border-color: $salmon;
    background: linear-gradient(97.32deg, #FFF3F2 1.07%, #FFFFFF 50.53%, #FFF3F2 100%);
  }

  &--bronze {
    border-color: #F6D4A2;
    background: linear-gradient(97.32deg, #FCEEDA 1.07%, #FFFFFF 50.53%, #FCEEDA 100%);
  }

  &--silver {
    border-color: $thali01;
    background: linear-gradient(99.53deg, #F0EFEF 1.12%, #FFFFFF 50.56%, #F0EFEF 100%);
  }

  &--gold {
    border-color: $cremeBrulee;
    background: linear-gradient(281.09deg, #FFF5E7 3.48%, #FFFFFF 51.1%, #FFF5E7 98.72%);
  }

  &--platinum {
    border-color: $thali01;
    background: linear-gradient(95.73deg, #E1DFDF 0.71%, #FFFFFF 55.32%, #E1DFDF 100%);
  }

  &--diamond {
    border-color: $starflower;
    background: linear-gradient(95.73deg, #E5EDFF 0.71%, #FFFFFF 55.32%, #E5EDFF 100%);
  }

  &--elite {
    border-color: $purpleMiddle;
    background: linear-gradient(95.73deg, #EDE3FC 0.71%, #FFFFFF 55.32%, #EDE3FC 100%);
  }
}