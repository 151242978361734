@import "../../../styles/var";

#shop-performance {
  position: absolute;
  background-color: $feta;
  top: 80px !important;
  left: 110px !important;
  right: 0;
  bottom: 0;
  padding: 24px 16px 32px 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100% !important;
    box-sizing: border-box;
    height: 60px;

    .sticky-header {
      position: sticky !important;
      position: -webkit-sticky !important;
      display: flex;
      align-items: center;
      left: 110px;
    }

    .results-filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 16px;
      margin-top: -8px;
      text-transform: capitalize;

      background: $soySauce;
      border-radius: 5px;
      padding: 16px;

      max-height: 60px;
      gap: 8px;

      :first-child {
        display: flex;

        :last-child {
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          color: $sourCream;
          letter-spacing: .01em;
        }
      }

      img {
        cursor: pointer;
        height: 24px;
        width: 24px;
      }
    }
  }

  table {
    background-color: $feta;
    border-collapse: unset !important;
    border-spacing: 8px !important;
    border: none !important;
    padding-bottom: 32px !important;
    position: absolute;
    top: 112px;
    margin-left: -7px !important;
    padding-right: 8px;
    width: auto;

    td, th, .td, .th {
      border-radius: 5px;
      padding: 0;
      text-align: center;
      vertical-align: middle;
      color: $truffle;
      box-shadow: 0 0 0 2px $feta;
      min-width: 80px;
      max-width: 300px;
      font-size: 15px;
      font-weight: 450;
    }

    td, .td {
      border: 1px solid $haddock;
      background-color: #fff;
    }

    th, .th {
      font-weight: 600;
      background: $feta;
    }

    .td, .th {
      margin-left: 16px;
      background-color: $garlic;
      border: 1px solid $haddock;
    }

    .td {
      height: 80px;
      line-height: 80px;
    }

    .th {
      height: 64px;
      line-height: 64px;
    }

    th.lead-column, td.lead-column {
      border-radius: unset;
      border: 0;
      background-color: $feta;
      text-align: unset;
      vertical-align: unset;
      color: unset;
      box-shadow: unset;
    }

    //#thIcon {
    //    width: 28px;
    //    height: 28px;
    //    opacity: 0.99;
    //    padding-bottom: 1px;
    //}
    //
    tr {
      //    height: 56px !important;
      white-space: nowrap;
      background-color: $feta;
      position: sticky !important;
    }

    .whiteText {
      color: $sourCream;
    }

    .redText {
      color: $fmoRed;
    }

    .greenText {
      color: $artichoke;
    }

    .purpleText {
      color: $purple;
    }

    .sticky-col {
      z-index: 110 !important;
    }

    .sticky-row::before, .sticky-row1::before {
      content: "";
      position: absolute;
      width: calc(100% + 8px);
      height: 24px;
      background-color: $feta;
      outline: 3px solid $feta;
      left: 0;
      top: -28px;
      border-radius: 0px !important;
    }

    .sticky-row, .sticky-row1, .sticky-row2 {
      position: sticky;
      position: -webkit-sticky;
      top: 114px;
      background-color: $garlic;
      border: 1px solid $haddock;
      //z-index: 100 !important;
      outline: 8px solid $feta;

      //th:nth-child(-n + 2) {
      //    background-color: $feta;
      //}
    }

    .sticky-row1 {
      text-align: left;
      font-size: 12px;
      font-weight: 600;
      border: 0;
      background-color: $feta;
      padding-left: 4px;
      padding-right: 0;
      margin-left: 16px;

      .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .month {
          margin-left: 16px;
          text-transform: uppercase;
          letter-spacing: 0.03em;
        }

        .line {
          margin-left: 16px;
          flex: 1;
          height: 10px;
          border-radius: 5px;
          background-color: $haddock;
        }
      }
    }

    .sticky-row2 {
      top: 135px;
      width: 70px;
      min-width: 70px;
      max-width: 70px;
      height: 64px;
      padding: 0;

      img {
        margin-bottom: -4px;
        padding-top: 2px;
      }
    }

    .status {
      &-green {
        background-color: $courgette;
        color: $truffle;
        border: 1px solid $rocket;

      }

      &-red {
        background-color: $bubblegum;
        color: $fmoRed;
        border: 1px solid $sorbet;
      }
    }

    .toggler {
      min-width: 60px;
      max-width: 60px;
      width: 60px;
      color: $darkText !important;
    }

    .wrapper {
      position: relative;
      white-space: nowrap;
    }

    .headcol {
      position: sticky;
      position: -webkit-sticky;
      min-width: 312px;
      max-width: 312px;
      width: 312px;
      left: 122px;
      outline: 8px solid $feta;
      min-height: 80px;
      max-height: 80px;
      height: 80px;
      text-align: left;

      &:nth-child(2) {
        left: 214px;
      }

      &--totals {
        font-weight: 600;
        text-align: center;
      }
    }

    .flex-basic {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .col-1 {
        flex: 1;
        .row-1 {
          margin-left: 16px;
          font-weight: 600;
          cursor: pointer;
        }

        .row-2 {
          margin-left: 16px;
          margin-top: 8px;
          font-weight: 500;
        }
      }

      .col-2 {
        flex: 0;
        margin-right: 16px;
          img {
            cursor: pointer;
          }
      }
    }

    .toggler-inner {
      height: 56px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .toggler-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }

      .toggler-right {
        flex-grow: 1;
        text-align: end;
        padding-top: 4px;
        padding-left: 8px;
      }
    }

    .hidden {
      display: none !important;
    }

    .promo_col {
      width: 80px;
      z-index: 100;

      img {
        margin: 0 auto;
      }

      img:nth-child(2) {
        margin: 0 8px;
      }
    }

    .row {
      & .headcol {
        & div {
          position: relative;
          z-index: 2;
        }

        &:after {
          content: '';
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          background: repeating-linear-gradient(
            -45deg,
            $pancetta,
            $pancetta 10px,
            $sorbet 10px,
            $sorbet 20px
          );
          opacity: 0.4;
          border-radius: 5px;
        }
      }

      &:after {
        content: '';
        height: 83px;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background: repeating-linear-gradient(
          -45deg,
          $pancetta,
          $pancetta 10px,
          $sorbet 10px,
          $sorbet 20px
        );
        opacity: 0.4;
        border-radius: 5px;
      }
    }
  }
}

@media (max-width: 768px) {
  #shop-performance {
    position: relative;
    height: 100vh !important;
    width: 100vw !important;
    top: 80px !important;
    left: 0 !important;
    padding: 16px 16px 32px 16px;
    box-sizing: border-box;    
  }

  .header {
    background-color: $feta;
    margin-bottom: 0;
    height: 68px !important;
    box-sizing: border-box !important;

    .sticky-header {
      // position: sticky !important; 
      // top: 96px; 
      // left: 16px !important;
      // background-color: $feta !important;
  
      .title {
        height: inherit;
        width: fit-content;
        font-size: 32px;

        display: flex;
        align-items: center !important;
        line-height: 34px !important;
      }
  
      .results-filter {
        height: 46px !important;
        padding: 0 16px !important;
      }
    }
  }

  .table {
    background-color: $feta !important;
  }

  .sticky-row::before, .sticky-row1::before {
    top: -24px !important;
  }

}

@media (max-width: 768px) and (orientation: landscape) {
  .header {
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;

    .title {
      width: max-content !important;
    }

  }
  .table { 
    display: none !important;
  }
}
