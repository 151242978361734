@import "../../../../styles/var";

.timer-tag {
  height: 32px;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: $soySauce;
  border-radius: 4px;

  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  letter-spacing: 0.01em;
  color: $sourCream;

  @media screen and (min-width: $tablet) {
    padding: 0 8px;
    margin-left: auto;
  }

  &__timer {
    width: 64px;
  }
}