@import "../../../styles/var";

.pipeline-overview {
  height: fit-content;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $sourCream;

  @media screen and (min-width: 900px) {
    left: 110px;
    background-color: inherit;
  }

  &__inner {
    padding: 24px 16px 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media screen and (min-width: 900px) {
      margin: 24px 16px 32px 0;
      padding: 0;
      gap: 16px;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: $sourCream;

    @media screen and (min-width: 768px) {
      padding: 32px;
      border: 1px solid $eel;
      border-radius: 8px;
    }

    &--timeline {
      padding-top: 32px;
      border-top: 1px solid $eel;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: $soySauce;

    @media screen and (min-width: $tablet) {
      display: none;
    }
  }

  &__button-wrapper {
    display: none;

    @media screen and (min-width: $tablet) {
      display: flex;
      gap: 16px;
    }
  }

  &__tag-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &--column {
      flex-direction: column;
    }
  }

  &__timeline-title {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.3px;
    color: $darkText;

    @media screen and (min-width: $tablet) {
      font-size: 26px;
      line-height: 33.8px;
    }
  }
}