@import "../../../styles/var";

.log-error {
  height: 56px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: $cremeBrulee;

  &__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: 0.005em;
  }
}

.text-area {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__wrapper {
    min-height: 200px;
    display: flex;
    flex: 1;
  }

  &__inner {
    width: 100%;
    padding: 16px 32px;
    background-color: $garlic;
    border: none;
    resize: none;
    flex: 1;

    font-size: 15px;
    font-weight: 400;
    line-height: 21.75px;
    color: $darkText;
    
    &::placeholder {
      color: $thali02;
    }
  }
}