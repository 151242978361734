@import "../../../styles/var";

#ExpandedLogModal.Modal {
  max-width: 504px;
}

.expanded-log {

    &__header {
      padding: 24px 24px 16px 32px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-bottom: 1px solid $eel;
    }

    &__title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      margin: 0;
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
      color: $soySauce;
      text-transform: capitalize;
    }

    &__close-wrapper {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: 1px solid $eel;
      cursor: pointer;
    }

    &__close-button {
      height: 16px;
      width: 16px;
    }

    &__header-tags {
      display: flex;
      gap: 8px;
    }

    &__content-wrapper {
      padding: 24px 32px;
    }

    &__content {
      margin: 0;
      padding: 8px 16px;
      border-radius: 8px;
      background-color: $garlic;
      color: $truffle;
      font-size: 15px;
      line-height: 21.75px;
    }
}