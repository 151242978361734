@import "../../../styles/var.scss";

.stat-card {
  height: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid $eel;
  border-radius: 8px;
  box-sizing: border-box;

  @media screen and (min-width: $tablet) {
    height: 130px;
  }

  &--platform-card {
    height: 88px;

    @media screen and (min-width: $tablet) {
      height: 144px;
    }
  }

  &--warning {
    border: 2px solid $salmon;
  }

  &--disabled {
    border-color: $haddock;
  }

  &__heading {
    font-size: 14px;
    font-weight: 600;
    line-height: 18.9px;
    opacity: 0.8;

    @media screen and (min-width: $tablet) {
      font-size: 15px;
      line-height: 20.25px;
    }

    &--warning {
      color: $fmo;
    }

    &--disabled {
      color: $thali02;
    }
  }

  &__value {
    font-size: 22px;
    line-height: 28.6px;
    letter-spacing: 0.01em;
    font-weight: 800;
    color: $soySauce;

    @media screen and (min-width: $tablet) {
      font-size: 26px;
      line-height: 32.5px;
    }

    &--warning {
      color: $fmo;
    }

    &--disabled {
      color: $thali01;
    }
  }

  &__tag-wrapper {
    display: flex;
    gap: 8px;
  }

  &__joined-logo {
    width: 24px;
    height: 24px;
  }
}