@import "../../../styles/modal.scss";
@import "../../../styles/var.scss";

#AddNewDeviceModal.Modal {
  width: 500px;
  max-height: 700px;

  -webkit-animation: opacity 0.2s ease-in-out;
  -moz-animation: opacity 0.2s ease-in-out;
  -o-animation: opacity 0.2s ease-in-out;
  animation: opacity 0.2s ease-in-out;
  transition: 0.2s ease-out;
}

.new-device-modal {
  padding: 32px;
  color: $truffle;

  &__header {
    display: flex;
    justify-content: space-between;
    color: $soySauce;
  }

  &__title {
    margin: 0 0 8px;
    font-weight: 700;
    font-size: 26px;
  }

  &__close-icon {
    cursor: pointer;
  }

  &__serial-number {
    width: fit-content;
    height: 24px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    background: $sorbet;
    font-weight: 600;
    font-size: 11px;
    color: $fmoRed;
    border-radius: 5px;
  }

  &__option-heading {
    margin-top: 24px;
    font-weight: 600;
    font-size: 15px;
  }

  &__type-option {
    height: 46px;
    padding: 0 16px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $haddock;
    border-radius: 5px;
    cursor: pointer;

    &--active {
      font-weight: 600;
      color: $fmoRed;
      background: $bubblegum;
      border-color: $sorbet;
      transition: 0.2s ease-out;
    }
  }

  &__active-icon {
    height: 20px;
    width: 20px;
  }

  &__submit-type-button {
    width: 100%;
    margin-top: 16px;
    border: none;
    background: $soySauce;
    color: $sourCream;
    transition: 0.2s ease-out;

    &:disabled {
      opacity: 0.2;
    }
  }
}