@import "../../styles/var";

.ticket-card {
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: $radius;
  border: 1px solid $eel;

  @media screen and (min-width: $tablet) {
    min-height: 260px;
    padding: 24px;
  }

  &--opens-modal {
    cursor: pointer;
  }

  &--log {
    background-color: $garlic;
    border-color: $garlic;
  }

  &--visit-log {
    background-color: $stilton;
    border-color: $stilton;
  }

  &--scraper {
    background-color: $banoffee;
    border-color: $banoffee;
  }

  &--marketing {
    background-color: $butter;
    border-color: $butter;
  }

  &--pinned {
    background-color: $bubblegum;
    border-color: #F6BCBC;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
  }

  &__tag-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__title {
    margin: 12px 0 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 21.6px;
    color: $soySauce;
    text-transform: capitalize;

    @media screen and (min-width: $tablet) {
      margin-top: 16px;
    }

    &--job {
      color: $darkText;
    }

    &--job-completed {
      color: $artichoke;
    }

    &--log-pinned {
      color: $fmo;
    }
  }

  &__button-wrapper {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $haddock;
    border: 1px solid $eel;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: border-box;

    &--log {
      background-color: $sourCream;
    }
  }

  &__description {
    margin: 8px 0 auto;
    font-size: 15px;
    font-weight: 400;
    line-height: 21.75px;
    color: $truffle;

    @media screen and (min-width: $tablet) {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }

    &--my-jobs {
      @media screen and (min-width: $tablet) {
        -webkit-line-clamp: 3;
      }
    }
  }

  &__footer {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;

    @media screen and (min-width: $tablet) {
      margin-top: 16px;
    }
  }

  &__footer-right {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
