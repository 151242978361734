@import "../../styles/var";

$condensedHeight: 40px;
$standardHeight: 48px;

.button {
  min-height: $standardHeight;
  border-radius: $radius-mobile;
  padding: 0 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: $text;
  transition: 0.25s;
  box-sizing: border-box;
  border: none;
  white-space: nowrap;

  &:disabled {
    background-color: $garlic;
    border: 1px solid $haddock;
    color: $thali01;
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }

  &--grow {
    flex: 1;
  }

  &--square, &--round {
    width: $standardHeight;
    padding: 0;
  }

  &--condensed {
    min-height: $condensedHeight;
    height: $condensedHeight;
    padding: 0 12px;
    font-size: 15px;
    line-height: 20.25px;

    &.button--square, &.button--round {
      width: $condensedHeight;
    }
  }

  &--round {
    border-radius: 100%;
  }

  &--primary {
    background-color: $soySauce;
    color: $sourCream;
    border: 1px solid $soySauce;

    &:hover {
      background-color: $truffle;
    }
  }

  &--secondary {
    background-color: $haddock;

    &:hover {
      background-color: $eel;
    }
  }

  &--tertiary {
    background-color: $sourCream;
    border: 1px solid $eel;

    &:hover {
      border-color: $thali01;
    }
  }

  &--quaternary {
    background-color: $sourCream;
    border: 1px solid $eel;

    &:hover {
      border-color: $thali01;
    }
  }

  &--success {
    background-color: $kiwi;
    border: 1px solid $kiwi;

    &:hover {
      background-color: $pistachio;
      border-color: $pistachio;
    }
  }

  &--danger {
    background-color: $sourCream;
    border: 1px solid $eel;
    color: $fmo;

    &:hover {
      background-color: $fmo;
      border-color: $fmo;
      color: $sourCream;
    }
  }

  &--brand {
    background-color: $fmo;
    color: $sourCream;
  }

  &__wrapper--grow {
    display: flex;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }
}