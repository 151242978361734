@import "../../styles/var";

.tag {
  padding: 3px 8px;
  display: inline-flex;
  gap: 4px;
  align-items: center;
  background-color: $sourCream;
  border: 1px solid $eel;
  border-radius: $radius-mobile;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: $truffle;
  box-sizing: border-box;
  // white-space: nowrap; // do not add, will break elsewhere (my jobs)

  &--large {
    min-height: 32px;
  }

  &--medium {
    min-height: 24px;
  }

  &--small {
    min-height: 20px;
  };

  &--x-small {
    min-height: 16px;
    padding: 0 4px;
  };

  &--pointer {
    cursor: pointer;
  }

  &--no-shrink {
    flex-shrink: 0;
  }

  &--highlight {
    border: 1px solid $yolk;
  }

  &--creme-brulee {
    background-color: $cremeBrulee;
    border: 0;
  }

  &--blueberry {
    background-color: $blueberry;
    border: 0;
    color: $sourCream;
  }

  &--completed {
    border-color: $kiwi;
    background-color: $courgette;
  }

  &--fmo {
    background-color: $fmo;
    border: 0;
    color: $sourCream;
  }

  &--clementine {
    background-color: $clementine;
    border: 0;
    color: $sourCream;
  }

  &--medium-priority {
    background-color: #FF9800;
    border: 0;
    color: $sourCream;
  }

  &--low-priority {
    background-color: $artichoke;
    border: 0;
    color: $sourCream;
  }

  &--primary {
    background-color: $soySauce;
    color: $garlic;
    border: 0;
  }

  &--required-default {
    border-color: $butter;
    background-color: $banoffee;
    color: #BB7711;
  }

  &--required-focus {
    border: 0;
    background-color: #BB7711;
    color: $sourCream;
  }

  &--secondary {
    color: $text;
    background-color: $haddock;
  }

  &--success {
    border-color: $kiwi;
    background-color: $courgette;
  }

  &--eel {
    background-color: $eel;
    border: 0;
    color: $text
  }

  &--date-grey {
    background-color: $garlic;
  }

  &--bubblegum {
    background-color: $bubblegum;
    border-color: #F6BCBC;
    color: $fmo;
  }

  &--plum {
    background-color: $plum;
    border: 0;
    color: $sourCream;
  }

  &--stilton {
    border-color: $starflower;
    background-color: $stilton;
  }

  &__text {
    font-weight: 600;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 1;
    // overflow: hidden; ---- moved to below code to clip text, remove commented styles if no issues arise
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--large {
      font-size: 13px;
      line-height: 18.2px;
    }

    &--medium {
      font-size: 12px;
      line-height: 14.4px;
    }

    &--small {
      font-size: 11px;
      line-height: 13.2px;
    }

    &--x-small {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.03em;
    };

    &--time {
      font-weight: 500;
    }
  }
}