@import "../../../styles/var";

.modal-options {

  &__option-wrapper {
    cursor: pointer;
    background-color: $feta;

    &:hover {
      .modal-options__option-button {
        background-color: $garlic;
      }
    }

    &:last-child {
      .modal-options__option {
        margin: 0;
        padding: 0 16px;
      }
    }

    &--disabled {
      background-color: $sourCream;
      cursor: default;
    }
  }

  &__option {
    height: 48px;
    margin: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $eel;
    box-sizing: border-box;
  }
  
  &__option-inner {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  &__option-title {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 20.25px;
    letter-spacing: 0.005em;
    color: $truffle;

    &--disabled {
      color: $thali01;
    }
  }
  
  &__option-button {
    height: 20px;
    width: 20px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $thali02;
    box-sizing: border-box;
    border-radius: 100%;
    background-color: $sourCream;
  
    &--active {
      border-color: #1E1A1A;
    }
  }
  
  &__option-active {
    width: 14px;
    height: 14px;
    background-color: #1E1A1A;
    border-radius: 100%;
  }

  &__sub-value {
    font-size: 15px;
    font-weight: 500;
    line-height: 21.75px;
    color: $truffle;
    opacity: 0.75;

    &--disabled {
      color: $thali01;
    }
  }
}