@import "../../../../styles/var";

.error-banner {
  padding: 16px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-start;
  background-color: $bubblegum;
  position: sticky;
  top: 120px;
  z-index: 2;

  @media screen and (min-width: $tablet) {
    position: static;
    border-radius: 8px;
  }

  &--warning {
    background-color: $cremeBrulee;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__heading {
    font-size: 15px;
    font-weight: 600;
    line-height: 20.25px;
    color: $soySauce;
  }

  &__subheading {
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: 0.005em;
    color: $truffle;
  }

  &__icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}