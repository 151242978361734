@import "../../../styles/var.scss";

.search-field {
  height: 40px;
  padding: 0 12px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid $eel;
  border-radius: 4px;
  box-sizing: border-box;

  @media screen and (min-width: $tablet) {
    width: 288px;
  }

  &--focussed {
    padding: 0 11px;
    border: 2px solid $thali01;
  }

  &--disabled {
    border-color: $haddock;
  }

  &__icon {
    width: 16px;
    height: 16px;
  }

  &__input {
    width: 100%;
    padding: 0;
    border: none;

    font-size: 15px;
    font-weight: 500;
    line-height: 20.25px;

    &::placeholder {
      font-weight: 400;
    }

    &:disabled {
      background-color: $sourCream;

      &::placeholder {
        color: $thali01;
      }
    }
  }

  &__clear-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}