@import "../../../styles/var";

.prospect-form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: $tablet) {
    gap: 32px;
  }

  &--prospect-overview {
    padding-top: 8px;

    @media screen and (min-width: $tablet) {
      padding-top: 16px;
    }
  }
}