@import "../../../../styles/var.scss";

.info {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  color: $thali02;

  @media screen and (min-width: 768px), print {
    gap: 40px;
    grid-template-columns: 1fr 1fr 190px;
  }

  &__section {
    display: flex;
    flex-direction: column;
    flex-basis: 25%;

    &--apps {
      flex-basis: 18%;
    }
  }

  &__item {
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--icon {
      flex-direction: row;
      align-items: center;
      padding: 8px;
      margin-bottom: 8px;
      border-radius: 8px;
      border: 1px solid $thali02;
    }
  }

  &__key {
    font-size: 12px;
  }

  &__value {
    padding-top: 4px;
    font-weight: 600;
  }
}