@import "../../styles/var";

.selection-box {
  height: 56px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $eel;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    border-color: $thali01;
  }

  &--disabled {
    border-color: $haddock;
    cursor: auto;

    &:hover {
      border-color: $haddock;
    }
  }

  &--active {
    background-color: $haddock;
    border-color: $thali01;
  }

  &__heading {
    margin: 0 auto 0 8px;
    font-size: 15px;
    font-weight: 600;
    line-height: 20.25px;
    color: $truffle;

    &--disabled {
      color: $thali01;
    }
  }

  &__value {
    font-size: 15px;
    font-weight: 500;
    line-height: 21.75px;
    color: $truffle;

    &--disabled {
      color: $thali01;
    }
  }
}