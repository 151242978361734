@import "../../../styles/var";

.modal-header {
  min-height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 120;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  background-color: $sourCream;
  padding: 0 24px;
  border-bottom: 1px solid $eel;
  box-sizing: border-box;

  @media screen and (min-width: $tablet) {
    min-height: auto;
    padding: 24px 24px 16px 32px;
    align-items: flex-start;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    gap: 16px;

    @media screen and (min-width: $tablet) {
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
    }
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $tablet) {
      height: 32px;
      justify-content: center;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24.3px;
    color: $darkText;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 1;
    // overflow: hidden;
    white-space: nowrap;

    @media screen and (min-width: $tablet) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__subtitle {
    @media screen and (min-width: $tablet) {
      display: none;
    }
  }

  &__button-wrapper {
    min-height: 24px;
    min-width: 24px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;

    @media screen and (min-width: $tablet) {
      min-height: 32px;
      min-width: 32px;
      height: 32px;
      width: 32px;
      border: 1px solid $eel;
      border-radius: 100%;
    }
  }

  &__close-button {
    min-height: 24px;
    min-width: 24px;
    height: 24px;
    width: 24px;
    display: flex;

    @media screen and (min-width: $tablet) {
      min-height: 16px;
      min-width: 16px;
      height: 16px;
      width: 16px;
    }
  }

  &__tag-wrapper {
    display: flex;
    gap: 8px;
    // flex-shrink: 0; removed to prevent overlay titles from wrapping when long shop names present
  }
}