@import "../../../styles/var";

.user-tag {
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid $eel;
  border-radius: 4px;
  box-sizing: border-box;

  font-size: 15px;
  font-weight: 500;
  line-height: 20.25px;
  color: $truffle;

  &--ribbon {
    padding: 0;
    border: none;
    color: $sourCream;
  }

  &__bold {
    font-weight: 600;
  }
}