@import "../../../styles/var";

#transactions-history-wrapper {
    position: absolute;
    background-color: $feta;
    top: 80px !important;
    left: 0 !important;
    right: 0;
    bottom: 0;
    padding: 8px 16px 32px 0;
    display: flex;
    gap: 16px;
    flex-direction: column;
    height: fit-content;

    .section {
        background-color: $sourCream;
        border: 1px solid $haddock;
        border-radius: 5px;
        padding: 32px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 16px;
        
        -webkit-animation: opacity 0.2s ease-in-out;
        -moz-animation: opacity 0.2s ease-in-out;
        -o-animation: opacity 0.2s ease-in-out;
        animation: opacity 0.2s ease-in-out;
    }

    .transactions-history {

        .title {
            margin-bottom: 16px;
        }

        table {
            border-collapse: unset !important;
            border-spacing: 8px !important;
            border: none !important;

            td,
            th {
                width: calc(100vw / 4);
                border-radius: 5px;
                height: 56px;
                max-height: 56px;
                padding: 0 16px;
                border: 1px solid $haddock;
                background-color: $sourCream;
    
                font-size: 15px;
                font-weight: 450;
                color: $truffle;
                vertical-align: middle !important;
                text-align: center;
            }

            thead {

                th {
                    color: $truffle;
                    font-weight: 600;
                    font-size: 15px;
                    background: $garlic;
                    border-color: $haddock;
        
                    &:nth-child(2) {
                        background-color: $sourCream !important;
                        border: none !important;
                        padding: 0;
                    }

                    input {
                        height: 56px;
                        width: 100%;
                        border: 1px solid $haddock;
                        box-sizing: border-box;
                        border-radius: 5px;
                        font-weight: 500;
                        font-size: 15px;
                        display: flex;
                        align-items: center;
                        padding-left: 48px;
                        line-height: 56px;
                        
                        color: $soySauce;
                    }
        
                    input[type="text"]:focus {
                        border-color: $sorbet !important;
                    }
        
                    input[type="text"]::placeholder {
                        color: $thali01;
                    }
                }
            }
            
            .green {
                td {
                    background-color: $courgette;
                    border-color: $rocket;
                }
                :nth-child(2) {
                    color: $artichoke;
                    font-weight: 600;
                    text-transform: capitalize;
                }
            }

            .purple {
                td {
                    background-color: $macaroon;
                    border-color: $purplePale;
                }

                :nth-child(2) {
                    color: $purple;
                    font-weight: 600;
                    text-transform: capitalize;
                }
            }

            .red {
                td {
                    background-color: $bubblegum;
                    border-color: $sorbet;
                }

                :nth-child(2) {
                    color: $fmoRed;
                    font-weight: 600;
                    text-transform: capitalize;
                }
            }

            .white {
                td {
                    background-color: $sourCream;
                    border-color: $haddock;
                }

                :nth-child(2) {
                    color: $truffle;
                    font-weight: 600;
                    text-transform: capitalize;
                }
            }
        }
    }

    .transactions-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 16px;
    }
}
@media (min-width: 1600px) {
    
    .transactions-grid {
        grid-template-columns: repeat(3, 1fr) !important;

        div:nth-child(3) {
            grid-column-start: 1 !important;
            grid-column-end: span 3;
        }

        div:nth-child(4) {
            grid-column-start: 3 !important;
            grid-row-start: 1 !important;
        }
    }
}

@media (max-width: 990px) and 
(min-width: 768px) {
    .title {
        height: 46px !important;
        font-size: 32px !important;
        line-height: 46px !important;
        margin-bottom: 0 !important;  
    }

    .transactions-history {
        .table td, th
          {
            width: fit-content !important;
        }
    }
}

@media (max-width: 768px) {
    #transactions-history-wrapper {
        height: fit-content !important;
        position: relative;
        padding: 0;
        padding-bottom: 32px;
        top: 0 !important;
        left: 0 !important;

        .transactions-grid {
            display: flex !important;
            flex-direction: column;
            gap: 8px !important;
        }

        .transactions-history {
            @media screen and (orientation:portrait) {
                display: none !important;
            }

            @media screen and (orientation:landscape) {
              .table {
                display: block !important;
              }
            }
        }
    }
}