@import "../../../styles/var";

.counter {
  height: 40px;
  min-width: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $haddock;
  border: 1px solid $thali01;
  border-radius: 4px;
  box-sizing: border-box;

  font-size: 14px;
  font-weight: 600;
  line-height: 18.9px;
  color: $truffle;

  @media screen and (min-width: 768px) {
    padding: 0 16px;
    font-size: 15px;
    font-weight: 600;
    line-height: 20.25px;
  }
}