@import "../../../styles/var";

.dropdown-trigger {
  height: 48px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid $eel;
  box-sizing: border-box;
  cursor: pointer;
  background-color: $sourCream;

  &__title {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    line-height: 20.25px;
    letter-spacing: 0.01em;
    color: $text;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__label-wrapper {
    display: flex;
    gap: 16px;
  }

  &__icon-open {
    transform: rotate(180deg);
  }
}