@import "../../../styles/var";

.modal-tabs {
  display: flex;
  border-bottom: 1px solid $eel;

  &__tab {
    height: 48px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    border-left: 1px solid $eel;
  
    color: $thali02;
    font-size: 14px;
    font-weight: 600;
    line-height: 18.9px;
    letter-spacing: 0.01em;
  
    &:first-child {
      border-left: none;
    }
  
    &--active {
      color: $darkText;
  
      &::after {
        content: '';
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $kiwi;
      }
    }
  }
}