@import "../../../styles/var";

.image-upload {
  margin: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: $tablet) {
    margin: 0 32px;
  }

  &__cta {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px dashed $eel;
    border-radius: 8px;
    color: $truffle;
    cursor: pointer;
  }

  &__cta-heading {
    margin-top: 8px;
    font-size: 15px;
    font-weight: 600;
    line-height: 20.25px;
  }

  &__cta-desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.9px;
  }

  &__file {
    padding: 8px 16px;
    display: flex;
    gap: 16px;
    align-items: center;
    border: 1px solid $eel;
    border-radius: 8px;
  }

  &__icon-wrapper {
    display: flex;
    position: relative;
  }

  &__file-icon {
    width: 24px;
    height: 24px;
  }

  &__success-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    right: -2px;
    bottom: -2px;
  }

  &__file-text {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
  }

  &__file-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.9px;
    color: $soySauce;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__file-size {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: 0.02em;
    color: $thali02;
  }

  &__close-wrapper {
    min-width: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $haddock;
    border-radius: 100%;
    cursor: pointer;
  }

  &__close-icon {
    width: 16px;
    height: 16px;
  }

  &__input {
    display: none;
  }
}