@import "../../styles/var.scss";

.disclaimer {
    box-sizing: border-box !important;
    max-width: fit-content;
    height: fit-content;
    padding: 16px;
    margin-top: 8px;

    display: none;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;

    background-color: $bubblegum;
    border-radius: 5px;

    color: $fmoRed;
    font-weight: 600;
    font-size: 15px;

    div {
        max-width: 360px;
    }
}

@media (max-width: 768px) and (orientation: landscape) {
    .disclaimer {
        display: flex !important;
    }
}