@import "../../../styles/var";

.savings {
  position: relative;
  background-color: $feta;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px 16px 32px;

  @media screen and (min-width: 768px) {
    padding: 24px 0 32px;
    left: 110px;
    position: absolute;
  }

  &__form-wrapper {
    margin: 0 auto;
    background: $sourCream;
    border-radius: 5px;
    border: 1px solid $haddock;
    padding: 12px 16px 32px;

    @media screen and (min-width: 768px) {
      width: fit-content;
      padding: 12px 32px 32px 174px;
    }
  }

  &__input-wrapper {
    padding: 20px 16px;
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    gap: 8px 24px;
    position: relative;

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr auto;
      gap: 8px 40px;
    }

    &--orders {
      position: relative;
      border-top: 1px solid $fmoRed;
    }

    &--value:after {
      content: '£';
      position: absolute;
      bottom: 41px;
      left: 25%;

      @media screen and (min-width: 768px) {
        left: 250px;
        bottom: initial;
      }
    }
  }

  &__input {
    height: 46px;
    margin: 8px 0;
    padding-left: 16px;
    border: 1px solid #EDEBEB;
    border-radius: 5px;
    color: $soySauce;
    font-weight: 500;
    font-size: 15px;
    box-sizing: border-box;
    text-align: center;

    @media screen and (min-width: 768px) {
      margin: 0;
    }
  }

  &__error-message {
    padding-bottom: 8px;
    display: block;
    text-align: center;
    font-size: 0.8em;
    color: $pepperoni;
  }

  &__orders-label {
    margin-bottom: 16px;
    left: -144px;
    top: 33px;
    color: $fmoRed;
    font-weight: 500;

    @media screen and (min-width: 768px) {
      margin: 0;
      position: absolute;
    }
  }

  &__submit-wrapper {
    padding-top: 20px;
    border-top: 1px solid $fmoRed;
  }

  &__button {
    border-radius: 5px;
    width: 230px;
    height: 46px;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    display: block;
    margin: 0 auto;

    &--submit {
      background-color: $fmoRed;
      border: none;
      color: $sourCream;
    }

    &--reset {
      background-color: $bubblegum;
      border: 1px solid $sorbet;
      color: $fmoRed;
    }
  }

  &__results-wrapper {
    width: fit-content;
    margin: 0 auto;
    background: $sourCream;
    border-radius: 5px;
    border: 1px solid $haddock;
    padding: 16px;

    @media screen and (min-width: 768px) {
      padding: 32px;
      font-size: 18px;
    }
  }

  &__results-title {
    padding-bottom: 24px;
    display: block;
    font-weight: 900;
    font-size: 24px;
    color: $fmoRed;

    @media screen and (min-width: 768px) {
      padding-bottom: 40px;
      font-size: 32px;
    }
  }

  &__results {
    text-align: center;
    max-width: 432px;

    @media screen and (min-width: 768px) {
      margin-left: 128px;
    }
  }

  &__headline-wrapper {
    padding-bottom: 32px;
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: auto auto;
    gap: 12px 24px;
    align-items: center;
    justify-items: start;
    justify-content: center;
    border-bottom: 1px solid $fmo;

    @media screen and (min-width: 768px) {
      padding-bottom: 40px;
      margin-bottom: 32px;
      gap: 16px 40px;
    }
  }

  &__headline {
    font-size: 18px;

    @media screen and (min-width: 768px) {
      font-size: 22px;
    }
  }

  &__headline-figure {
    font-size: 26px;
    font-weight: 900;
    color: $fmoRed;

    @media screen and (min-width: 768px) {
      font-size: 36px;
    }
  }

  &__cost {
    color: $fmoRed;
    font-weight: 700;
  }

  &__comparison {
    display: flex;
    flex-direction: column;
    margin: 32px 0 40px;
    font-weight: 600;
  }

  &__subtext {
    padding-top: 8px;
    font-size: 14px;
    font-weight: 400;

    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
}