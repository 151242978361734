@import "../../styles/var";

.input-header {
  padding: 0 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;

  &--modal {
    padding: 16px 24px;
    position: sticky;
    z-index: 1;
    top: -1px;
    border-bottom: 1px solid $eel;
    background-color: $sourCream;

    @media screen and (min-width: $tablet) {
      padding: 16px 32px;
    }
  }

  &--static {
    position: static;
    border: none;
  }

  &--large {
    gap: 8px
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--stacked {
      flex-direction: column-reverse;
      gap: 8px;
      align-items: baseline;
  
      @media screen and (min-width: $tablet) {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;

    &--small {
      gap: 8px;
    }
  }
  
  &__header-title {
    margin: 0 auto 0 0;
    font-weight: 600;
    color: $darkText;

    &--error {
      color: $fmo;
    }

    &--small {
      font-size: 16px;
      line-height: 20px;
    }

    &--medium {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.01em;
    }

    &--large {
      font-size: 26px;
      line-height: 33.8px;
      letter-spacing: normal;
    }
  }
  
  &__header-description {
    font-size: 15px;
    font-weight: 400;
    line-height: 21.75px;
    color: $text;

    &--error {
      color: $fmo;
    }
  }
}