@import "../../../styles/var";

.ticket-card-wrapper {
  display: grid;
  gap: 12px;
  animation: opacity 0.2s ease-in;

  @media screen and (min-width: $tablet) {
    gap: 16px;
  }

  @media screen and (min-width: 900px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (min-width: 1350px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}