@import "../../styles/var";
@import "../../index.scss";

#agent-jobs {
    position: absolute;
    background-color: $feta;
    top: 80px !important;
    left: 110px !important;
    right: 0;
    bottom: 0;
    padding: 24px 16px 32px 0 !important;
    height: fit-content;
    box-sizing: border-box !important;

    display: flex;
    flex-direction: column;
    gap: 16px;

    .HistoryModal,
    .CompleteModal {
        background: $sourCream;
        display: flex;
        flex-direction: column;
        border: none;
        padding: 32px;
        width: 500px;
        gap: 32px;
        overflow-y: auto;

        border-radius: 5px;
        box-shadow: 4px 4px 50px rgba(47, 38, 33, 0.05);
    }

    .status-badge {
        margin-top: -56px;
    }

    .quickLinks .label {
        margin-top: 0;
    }


    .CompleteModal {
        .header {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            display: flex;
            align-items: center;
            letter-spacing: 0.05em;
            text-transform: capitalize;

            color: $truffle;
            margin-bottom: 10px;
        }

        textarea {
            background: #fcfcfc;
            border-radius: 5px;
            border: none;
            height: 230px;
            padding: 20px;
            margin-bottom: 20px;
            font-weight: 450;
        }
    }

    .header-area {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 16px;
        box-sizing: border-box !important;

        .header-box {
            width: calc(100% / 3);
            box-sizing: border-box !important;
            background-color: $sourCream;
            border-radius: 5px;
            border: 1px solid $haddock;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 32px;
            cursor: pointer;

            .box {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border: none;
                background: none !important;
                gap: 16px;
                flex-grow: 1;
                margin: 0 !important;
                padding: 0 !important;
                height: 92px !important;


                :first-child {
                    font-weight: 500;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    color: $thali02;
                }

                :nth-child(2) {
                    font-weight: 800;
                    font-size: 50px;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: $thali01;
                }
            }

            img {
                height: 46px;
                width: 46px;
                margin-left: 16px;
            }
        }
    }

    .hideImg {
        display: none;
    }

    .toDoActive {
        background-color: $bubblegum !important;
        border-color: $sorbet !important;

        div:first-child {
            font-weight: 600 !important;
        }

        div:first-child,
        div:last-child {
            color: $fmoRed !important;
        }
    }

    .inReviewActive {
        background-color: $garlic !important;
        border-color: $haddock !important;
        
        div:first-child {
            font-weight: 600 !important;
        }

        div:first-child,
        div:last-child {
            color: $thali02 !important;
        }
    }

    .doneActive {
        background-color: $courgette !important;
        border-color: $rocket !important;

        div:first-child {
            color: $truffle !important;
            font-weight: 600 !important;
        }

        div:last-child {
            color: $artichoke !important;
        }
    }

    .jobs-area {
        background: $sourCream;
        border-radius: 5px;
        border: 1px solid $haddock;
        padding: 32px;

        .header-row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 32px;

            .title {
                font-size: 50px;
                padding: 0 0 0 4px !important;
            }

            .show-only {
                gap: 8px;

                :first-child {
                    margin-right: 8px;
                    color: $truffle;

                    @media screen and (max-width: $tablet) {
                        display: none;
                    }
                }

                button {
                    background: $sourCream;
                    border: 1px solid $haddock;
                    height: 60px;
                    padding: 0 16px;
                    border-radius: 5px;
                    margin-left: 10px;

                    color: $truffle;
                    font-weight: 500;

                    &.selected {
                        background: $bubblegum;
                        color: $fmoRed;
                        border-color: $sorbet;
                        font-weight: 600;
                    }
                }
            }

            input {
                border-radius: 10px;
                height: 44px;
                width: 300px;
                border: none;
                padding-left: 60px;
                margin-left: 40px;
                margin-top: 10px;
            }
        }

        .job-cards {
            display: grid;
            gap: 12px;

            @media screen and (min-width: $tablet) {
              gap: 16px;
            }

            @media screen and (min-width: 900px) {
              grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (min-width: 1350px) {
              grid-template-columns: repeat(3, 1fr);
            }

            .job-card {
                position: relative;
                height: 260px !important;
                padding: 16px;

                background: $purplePale;
                border: 1px solid $purpleMiddle;
                border-radius: 5px;

                display: flex;
                flex-direction: column;
                gap: 16px;

                -webkit-animation: opacity 0.2s ease-in-out;
                -moz-animation: opacity 0.2s ease-in-out;
                -o-animation: opacity 0.2s ease-in-out;
                animation: opacity 0.2s ease-in-out;

                .job-header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;
                    height: 32px;

                    font-size: 13px;
                    font-weight: 600;
                    letter-spacing: 0.01em;
                }

                .job-postcode,
                .job-date {
                    border-radius: 5px;
                    padding: 0 8px;
                    height: 100%;
                    width: fit-content;
                    display: flex;
                    align-items: center;
                }

                .job-postcode {
                    background-color: $purple;
                    color: $sourCream;
                }

                .job-date {
                    background-color: $sourCream;
                    color: $truffle;
                }

                .job-status,
                .job-status-comp {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: $purple;
                    color: white;
                    padding: 8px 16px;
                    box-sizing: border-box;
                    border-radius: 10px 0px;

                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    min-width: 130px;
                    text-align: center;
                }

                .job-status-comp {
                    color: $purple;
                    background: rgba(95, 9, 235, 0.15);
                }

                .job-title {
                    color: $purple;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    cursor: pointer;

                }
                
                .job-created-by {
                    color: $thali02;
                    font-weight: 500;
                    font-size: 14px;
                    letter-spacing: 0.01em;
                    line-height: 17px;
                    margin-top: -8px;
                    
                    .username {
                        text-transform: capitalize;
                        color: $thali02;
                        font-weight: 600;
                    }
                }

                .job-content {
                    font-weight: 450;
                    font-size: 15px;
                    line-height: 150%;
                    color: $truffle;
                    padding-right: 2px;

                    flex-grow: 1;
                    overflow: hidden;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                }

                button {
                    border: none;
                    background-color: $purpleMiddle;
                    box-sizing: border-box;
                    border-radius: 5px;
                    color: $purple;
                    width: 100%;
                    font-weight: 600;
                    font-size: 15px;
                    height: 46px;
                }
            }

            .job-card-urgent {
                background-color: $purple !important;
                border: $purple !important;

                .job-postcode {
                    background-color: $purplePale !important;
                    color: $purple !important;
                }

                .job-date {
                    background-color: $purpleMiddle !important;
                    color: $truffle !important;
                }

                .job-title {
                    color: $sourCream !important;
                }

                .job-content {
                    color: $sourCream !important;
                }

                button {
                    background-color: $sourCream;
                }
            }

            .log-card {
                background: $pink;

                .job-status {
                    color: $fmoRed;
                    text-transform: capitalize;
                    border: 1px solid $pink;
                    background: rgba(216, 43, 49, 0.15);
                }

                .job-title {
                    color: $fmoRed;
                }

                button {
                    border: 1px solid $fmoRed;
                    color: $fmoRed;
                }
            }
        }
    }
}


.timeline {
    display: flex;
    flex-direction: column;
    margin: 0;
    
    -webkit-animation: opacity 0.2s ease-in-out;
    -moz-animation: opacity 0.2s ease-in-out;
    -o-animation: opacity 0.2s ease-in-out;
    animation: opacity 0.2s ease-in-out;

    .single-timeline-item .text {
        min-height: 200px;
    }

    .timeline-item {
        display: flex;
        flex-direction: column;

        .created {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            display: flex;
            color: $truffle;

            &::before {
                display: none !important;
            }

            img {
                margin-right: 8px;
            }
        }

        .text {
            border: 1px solid $haddock;
            border-radius: 5px;

            padding: 16px;
            margin-top: 16px;
            height: fit-content !important;
            align-items: flex-start;

            color: $soySauce;
            font-weight: 450;
            font-size: 15px;
            line-height: 150%;
        }
    }
}

.completeButton {
    background-color: $purple;
    color: $sourCream;
    border: none;
}

.modalButtons {
    display: flex;
    flex-direction: column;
    height: 46px !important;
    border-radius: 5px;

    :first-child {
        background: $pink;
        color: $fmoRed;
        border: none;

        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
    }

    :last-child {
        background: $purplePale;
        border: none;
        color: $purple;

        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
    }
}


/* media queries */

@media (min-width: 1400px) and
(max-width: 1600px) {
    .job-card {
        height: 240px !important;
    }
}

@media (min-width: 1600px) {
    .job-cards {
        grid-template-columns: repeat(4, 1fr) !important;
    }

    .job-card {
        height: 220px !important;
    }
}

@media (max-width: 990px) and 
(min-width: 768px) {
    .box {
        div:nth-child(2) {
            font-size: 32px !important;
            line-height: 1;
        }
    }
}

@media (max-width: 768px) {

    #agent-jobs {
        position: relative;
        width: 100vw !important;
        box-sizing: border-box !important;
        overflow-x: hidden;
        padding: 96px 16px 32px 16px !important; 
        top: 0 !important;
        left: 0 !important;
        gap: 24px !important;
    }

    .header-area {
        display: none !important;
        flex-direction: column !important;
        gap: 8px !important;
        box-sizing: border-box !important;

        .header-box {
            padding: 0 24px !important;
            width: 100% !important;

            .box div:nth-child(2) {
                font-size: 36px !important;
            }
        }
    }

    .header-row {
        flex-direction: column !important;
        align-items: flex-start !important;
        gap: 16px;
        width: 100vw !important;
        margin-bottom: 16px !important;

        .title {
            padding: 0 !important;
            font-size: 36px !important;
        }

        .show-only {
            max-width: 100vw !important;
            justify-content: flex-start;
            align-self: flex-start;

            button {
                height: 46px !important;
            }
        }
    }

    .jobs-area {
        padding: 0 !important;
        border: none !important;
        background-color: $feta !important;
    }

    .job-cards {
        display: flex !important;
        flex-direction: column;
        gap: 8px;
    }
}