@import "../../../styles/var";

.prospect-ribbon {
  height: 40px;
  padding: 0 16px;
  position: sticky;
  top: 80px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  background-color: $fmo;

  @media screen and (min-width: $tablet) {
    display: none;
  }

  &--overview {
    display: flex;

    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  &--checked-in {
    background-color: $soySauce;
  }
}