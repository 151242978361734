@import "../../../index.scss";

@keyframes modalMobile {
    0% {
        position: absolute;
        top: 75%;
    }

    100% {
        top: 0%;
    }
}


#modal {
    display: none;
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.35) !important;
    justify-content: center;
    overflow: hidden !important;
    
    -webkit-animation: opacity 0.2s ease-in-out;
    -moz-animation: opacity 0.2s ease-in-out;
    -o-animation: opacity 0.2s ease-in-out;
    animation: opacity 0.2s ease-in-out;
}

#modal-content {
    min-height: 50%;
    max-height: 80%;

    min-width: 350px;   
    overflow-x: auto;
    width: auto;

    margin: auto 20px;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    position: relative;
    display: table;
}

#modal-content::-webkit-scrollbar {
    display: none;
}

.show-after {
    display: none;
}

.closeBtn {
    position: absolute;
    top: 36px;
    right: 40px;
    cursor: pointer;
}

  