@import "../../styles/var.scss";

#overview-page {
    background-color: #fcfcfc;
    top: 80px;
    left: 120px !important;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 24px 16px 32px 0 !important;

    .overview-content {
        z-index: 2;
        background: #fcfcfc;
        display: flex;
        flex-direction: column;

        &--logs {
          @media screen and (max-width: $tablet) {
            margin: 0 -16px;
            overflow: hidden;
          }
        }
    }

    .overview-tabs {
        display: flex;
        flex-wrap: nowrap;
        gap: 16px;
        width: 100%;
    }

    .tabs-dropdown-wrapper {
        background-color: $feta;
        width: 100vw;
        height: fit-content;
        box-sizing: border-box;
        position: fixed;
        top: 80px;
        left: 0;
        z-index: 100;
        padding: 0 16px;
    }

    .tabs-dropdown-wrapper-active {
        background-color: $sourCream !important;
        border-bottom: 1px solid $haddock;
    }

    .tabs-dropdown {
        padding: 16px 0;
        // box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.03);

        -webkit-touch-callout: none; 
        -webkit-user-select: none; 
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; 

        .tabs-dropdown-button {
            height: 46px;
            width: 100%;
            background-color: $sourCream;
            border: 1px solid $haddock;
            border-radius: 5px;
            padding: 0 24px;
            box-sizing: border-box;
    
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            color: $truffle;
            font-size: 16px;
            font-weight: 600;

            img {
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);

                -webkit-transition: 0.5s ease-out;
                -moz-transition: 0.5s ease-out;
                -o-transition: 0.5s ease-out;
                transition: 0.5s ease-out;
            }
    
            cursor: pointer;
        }

        .tabs-dropdown-button-active {
            background-color: $garlic !important;
            border-color: $thali01;

            img {
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(180deg);

                -webkit-transition: 0.5s ease-in;
                -moz-transition: 0.5s ease-in;
                -o-transition: 0.5s ease-in;
                transition: 0.5s ease-in;
            }
        }

        .dropdown-tabs-list {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin: 8px 0;
            background-color: none;
            
            -webkit-animation: 0.3s ease-in-out;
            -moz-animation: 0.3s ease-in-out;
            -o-animation: 0.3s ease-in-out;
            animation: opacity 0.3s ease-in-out;

            -webkit-transition: 0.3s ease-in-out;
            -moz-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;
        }

        .overview-tab, 
        .overview-tab-active {
            // width: 100%;
            margin: 0;
        }
    }

    .overview-tab {
        height: 46px;
        // width: calc(100vw / 4);
        margin-bottom: 16px;
        padding: 0 8px;

        background: $sourCream;
        border: 1px solid $haddock;
        border-radius: 5px;
        
        font-weight: 450;
        font-size: 15px;
        color: $thali02;
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: center;
        
        cursor: pointer;
        
        &:hover {
            background-color: $garlic;
        }

        @media screen and (min-width: 768px) {
          flex: 1;
        }
    }

    .overview-tab-active {
        color: $sourCream;
        background-color: $soySauce;
        border: none;
        font-weight: 500; 
        letter-spacing: 0.005em;

        &:hover {
            background-color: $soySauce;
        }
    }
}

.overview-header-id {
    display: none;

    @media screen and (min-width: 769px) {
      display: block;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: -0.01em;
      opacity: 0.8;
      color: $truffle;
    }
}