@import "../../../styles/var.scss";

.breakdown {
  @media screen and (min-width: 768px) {
    margin-bottom: 16px;
  }

  @media print {
    border: none;
    padding: 0;
  }

  &__section {
    background-color: $sourCream;
    border: 1px solid #F0EFEF;
    border-radius: 5px;
    padding: 24px;
    margin-bottom: 16px;

    @media screen and (min-width: 990px) {
      padding: 32px;
    }

    @media print {
      border: none;
      padding: 0;
      margin-bottom: 0;
    }
  }

  &__header {
    padding-bottom: 40px;
    display: flex;
    flex-direction: column-reverse;
    color: $soySauce;

    @media screen and (min-width: 768px), print {
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
    }

    @media screen and (min-width: 990px), print {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 990px), print {
      flex-direction: row;
      align-items: center;
    }
  }

  &__title {
    margin: 0 0 4px;
    font-weight: 900;
    font-size: 24px;

    @media screen and (min-width: 768px), print {
      font-size: 36px;
    }

    @media screen and (min-width: 990px), print {
      margin: 0;
    }
  }

  &__id-wrapper {
    display: flex;
    align-items: center;
  }

  &__id {
    color: $thali02;

    @media screen and (min-width: 990px), print {
      margin-left: 24px;
    }
  }

  &__popout-icon {
    width:16px;
    height: 16px;
    margin-left: 8px;
    cursor: pointer;
  }

  &__subtitle {
    padding-top: 16px;
    font-weight: 500;
    text-decoration: underline;

    @media screen and (min-width: 768px), print {
      font-size: 18px;
    }
  }
  
  &__logo {
    margin-bottom: 16px;
    width: 100%;
    border-radius: 5px;

    @media screen and (min-width: 768px), print {
      width: 128px;
      height: 128px;
      margin: 0 24px 0 0;
    }

    @media screen and (min-width: 990px), print {
      margin: 0;
    }
  }

  &__heading {
    margin-top: 0;
    padding-bottom: 0;
    font-weight: 800;
    font-size: 26px;
    display: flex;
    align-items: center;
    color: $soySauce;

    @media screen and (min-width: 768px) {
      padding-bottom: 8px;
    }

    @media print {
      margin: 24px 0 0;
      padding: 16px 0 24px;
      border-top: 1px solid $haddock;
    }
  }

  &__box-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;

    @media screen and (min-width: 768px), print {
      padding: 0 16px;
      grid-template-columns: repeat(2, 1fr);
      gap: 32px;
    }

    @media screen and (min-width: 990px), print {
      grid-template-columns: repeat(3, 1fr);
    }

    &--platforms {
      grid-template-columns: repeat(2, 1fr);

      @media screen and (min-width: 990px), print {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  &__data-box {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid $haddock;
    border-radius: 5px;
    color: $thali02;
    background-color: $garlic;
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    &--uptrend {
      color: $artichoke;
      background: $courgette;
    }

    &--downtrend {
      color: $fmoRed;
      background: $bubblegum;
    }
  }

  &__data-value {
    padding-top: 8px;
    display: flex;
    align-items: center;
    font-size: 36px;
    font-weight: 900;

    &--secondary {
      margin-left: 8px;
      font-size: 16px;
      font-weight: 500;
    }
  }
}