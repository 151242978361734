@import "../../styles/var.scss";
@import "../../index.scss";

/* MOBILE NAV STYLING */

.nav-bar-wrapper {
    display: flex;
    flex-direction: column;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 400;
}

.nav-bar-mobile {
    align-items: center !important;
    justify-content: space-between !important;
    padding: 0 16px !important;
    position: relative;

    .nav-left {
        display: flex;
        flex-direction: row;
        flex: 1;
        gap: 8px;

        .nav-icon-top:first-child {
            margin: 0;
        }

        .nav-search-button {
            margin: 0;
            background-image: url(../../img/search.svg);
            background-position: center center !important;
            background-repeat: no-repeat;
            -webkit-transition: 0.2s ease-in-out;
            -moz-transition: 0.2s ease-in-out;
            -o-transition: 0.2s ease-in-out;
            transition: 0.2s ease-in-out;
        }

        .nav-search-button-clicked {
            background-color: $haddock !important;
            background-image: url(../../img/x.svg);
            background-repeat: no-repeat;
            background-position: center center !important;
            -webkit-transition: 0.2s ease-in-out;
            -moz-transition: 0.2s ease-in-out;
            -o-transition: 0.2s ease-in-out;
            transition: 0.2s ease-in-out;

            img {
                margin-top: 1px;
            }
        }
    }
    
    .buttonGroup {
        display: flex;
        flex-direction: row;
        flex-grow: 1;

        .filter-button {
            background-image: url(../../img/filter.svg);
            background-position: center center;
            background-color: $sourCream;
            border: 1px solid $haddock;
        }

        .nav-back-button {
            height: 40px;
            width: 40px;
            border: 1px solid $sorbet;
            box-sizing: border-box;
            background-color: transparent;
            background-image: url(../../img/arrowLeftRed.svg);
            background-position: center .75em;
            background-repeat: no-repeat;
        }

        img {
            cursor: pointer;
        }

        &--left-slot {
          margin-right: 8px;
          display: flex;
          gap: 8px;
        }

        &--right-slot {
          margin-left: auto;
          display: flex;
          gap: 8px;
        }
    }
} 

/* BURGER ICON ANIMATION */

.burgerMenuButton {
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid $eel;
    background-color: $sourCream;
    cursor: pointer;
}

.burger-icon {
    width: 24px;
    height: 24px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;

    span {
        position: absolute;
        height: 1.5px;
        width: 20px;
        background: $truffle;
        opacity: 1;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        border-radius: 100px;


        &:nth-child(1) {
            top: 5px;
        }

        &:nth-child(2) {
            top: 12px;
        }

        &:nth-child(3) {
        top: 19px;
        }
    }
}

.burger-icon-open {
    span {
        &:nth-child(1) {
            top: 12px;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &:nth-child(2) {
            width: 0%;
            opacity: 0;
        }

        &:nth-child(3) {
            top: 12px;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    }
}

/* BURGER MENU LINKS */

.burgerMenuLinks {
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 0;
    left: 0;
    padding: 16px !important;
    overflow: hidden;

    background-color: $sourCream;
    border-bottom: 1px solid $haddock;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.03);

    display: flex;
    flex-direction: column;
    gap: 32px;

    z-index: -1;
    opacity: 0;
    pointer-events: none;

    img {
        height: 46px;
        width: 46px;
    }

    div {
        height: 46px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        color: $fmoRed;
        font-weight: 600;
        font-size: 15px;

        cursor: pointer;

        &:hover {
            font-size: 16px;
            -webkit-transition: 0.2s ease-in-out;
            -moz-transition: 0.2s ease-in-out;
            -o-transition: 0.2s ease-in-out;
            transition: 0.2s ease-in-out;
        }

        h3, span {    
            color: $thali02;
            font-size: 26px;
            font-weight: 500;
        }

        span {
            color: $thali01;
            font-weight: 700;
        }   
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        width: 100vw;

        display: flex;
        flex-direction: column;
        gap: 16px;

        li {
            display: flex;
            align-items: center;
            gap: 16px;

            color: $truffle;
            font-size: 15px;
            font-weight: 450;

            cursor: pointer;

            // &:hover {
            //     font-weight: 600;
            //     font-size: 16px;
            //     transition: 0.2s ease-in-out;
            //     -webkit-transition: 0.2s ease-in-out;
            //     -moz-transition: 0.2s ease-in-out;
            //     -o-transition: 0.1s ease-in-out;
            // }
        }
    }
}

.burgerMenuActive {
    top: 80px;
    height: fit-content;
    overflow: auto;
    opacity: 1;
    pointer-events: auto;
    transition: 0.3s ease-in;
    -webkit-transition: 0.3s ease-in;
    -moz-transition: 0.3s ease-in;
    -o-transition: 0.3s ease-in;
}

/* SEARCH SECTION */

.searchSection {
    width: 100vw;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    opacity: 0;

    background: $sourCream;
    border-bottom: 1px solid $haddock;
    box-sizing: border-box;

    transition: 0.2s ease-out;
    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;

    .inputWrapper {
        display: flex;
        flex-direction: row;
    }

    input {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .nav-search-button {
        margin-left: -46px;
    }

    .searchBy {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 46px;

        .searchByText {
            padding-left: 4px;

            span {
                &:first-child {
                    font-size: 14px;
                    font-weight: 500;
                    color: $truffle;
                    line-height: 120%;
                    letter-spacing: 0.001em;
                }

                &:last-child {
                    font-size: 13px;
                    font-weight: 450;
                    color: $thali02;
                    letter-spacing: 0.002em;
                }
            }
        }

        img:first-child {
            margin-right: 8px;
        }
    }
}

.searchSectionActive {
    top: 79px;
    display: flex;
    opacity: 1;
    transition: 0.2s ease-in;
    -webkit-transition: 0.2s ease-in;
    -moz-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
}
