@import "../../styles/var";

.text-field {
  height: 56px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid $eel;
  display: flex;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;

  &:hover {
    border-color: $thali01;
  }

  &--focussed {
    border-width: 2px;
    padding: 0 15px;
  }

  &--disabled, &--disabled:hover {
    border-color: $haddock;
  }

  &--error, &--error:hover {
    border-color: #F6BCBC;
  }

  &__inner {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  &__label {
    height: 16px;
    margin: auto;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    transition: 0.2s;
    opacity: 0;
    cursor: text;

    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    letter-spacing: 0.02em;
    color: $truffle;

    &--filled {
      bottom: 22px;
      opacity: 1;
      cursor: default;
    }

    &--disabled {
      color: $thali01;
    }
  }

  &__input {
    height: 24px;
    padding: 0;
    margin: auto;
    border: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: top 0.2s;

    font-size: 15px;
    font-weight: 400;
    line-height: 20.25px;
    color: $soySauce;

    &--filled {
      top: 14px;
    }

    &::placeholder {
      color: $thali02;
    }

    &:disabled {
      color: $thali01;
      background-color: inherit;
      
      &::placeholder {
        color: $thali01;
      }
    }
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }
}