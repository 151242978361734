@import "../../../styles/var";

.location-form {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  font-size: 15px;
  position: relative;
  color: $truffle;

  &__label {
    font-weight: 600;
  }

  &__dropdown {
    max-height: 144px;
    position: absolute;
    top: 93px;
    left: 0;
    right: 0;
    z-index: 1;
    background: $sourCream;
    font-weight: 500;
    box-shadow: 0px 4px 20px 0px #0000000D;
    border: 1px solid $haddock;
    border-radius: 5px;
    cursor: pointer;
    overflow: scroll;
  }

  &__dropdown-option {
    height: 48px;
    padding: 16px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    &:hover {
      color: $sourCream;
      background: $thali02;
    }
  }

  &__section {
    position: inherit;
  }

  &__input-wrapper {
    position: relative;
    display: flex;
  }

  &__input {
    height: 48px;
    margin: 16px 0;
    padding: 0 16px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    color: $truffle;
    font-weight: 500;
    border: 1px solid $haddock;
    border-radius: 5px;
    cursor: pointer;

    &::placeholder {
      color: $thali02;
    }

    &:disabled {
      background: inherit;
      cursor: auto;
    }

    &--shop {
      cursor: text;
    }

    &--dropdown-active {
      background: $garlic;
      border: 1px solid #E1DFDF;
    }

    &--notes {
      height: 100px;
      padding: 16px;
      box-sizing: border-box;
      resize: none;
      cursor: text;

      &:read-only {
        cursor: auto;
      }
    }

    &--submit {
      margin: 0 auto;
      width: fit-content;
      padding: 0 32px;
      color: $sourCream;
      font-weight: 600;
      background: $soySauce;
      border: none;
      cursor: pointer;
      transition: 0.2s ease-out;

      &:disabled {
        background: $garlic;
        color: $thali01;
      }

      @media screen and (min-width: 768px) {
        margin: 0;
      }
    }
  }

  &__expand-icon {
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 17px;
    transform: rotate(90deg);
    cursor: pointer;
    transition: 0.2s ease-out;

    &--expanded {
      transform: rotate(-90deg);
    }
  }
}
