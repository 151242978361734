@import "../../../styles/var";

.serial-checker {
  min-height: calc(100vh - 80px - 56px);
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $sourCream;
  border-radius: 5px;
  border: 1px solid #F0EFEF;
  box-sizing: border-box;

  @media screen and (min-width: 768px) {
    padding: 32px;
  }

  &__barcode-icon {
    width: 64px;
    height: 64px;
    display: block;
  }

  &__title {
    margin: 12px 0 0;
    color: $soySauce;
    font-size: 26px;
    font-weight: 700;
    line-height: 130%;
  }

  &__subtitle {
    margin: 8px 0 24px;
    font-size: 15px;
  }

  &__serial-num-form {
    width: 100%;
    display: inline-grid;
    grid-template-columns: auto;
    gap: 16px;

    @media screen and (min-width: 768px) {
      width: auto;
      grid-template-columns: auto auto;
    }
  }

  &__input {
    border: 1px solid #F0EFEF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px 16px;
    font-size: 15px;
    font-weight: 500;
    color: #302A2A;
    text-align: center;
    justify-content: center;

    @media screen and (min-width: 768px) {
      width: 260px;
      text-align: initial;
      justify-content: initial;
    }

    &--submit {
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      background: $soySauce;
      color: $sourCream;
      border: none;
      transition: 0.2s ease-out;

      &:disabled {
        background: $garlic;
        color: $thali01;
        transition: none;
      }
    }
  }

  &__button-icon {
    margin-left: 12px;
  }
}