@import "../../styles/var";

$tableGutter: 8px;

.agent-overview {
  position: absolute;
  background-color: $feta;
  top: 80px;
  top: 104px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  @media screen and (min-width: $tablet) {
    padding: 0;
    left: 110px;
    top: 104px;
  }

  &__search-wrapper {
    width: 100%;
    height: 56px;
    padding: 0 16px 16px;
    position: fixed;
    top: 72px;
    z-index: 400;
    background-color: $sourCream;
    border-bottom: 1px solid $eel;
    box-sizing: border-box;
  }

  &__filter-button-wrapper {
    width: 100%;
    padding: 16px;
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    position: fixed;
    background-color: $feta;
    box-sizing: border-box;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (min-width: $tablet) {
      width: auto;
      margin: 0;
      padding: 24px 16px 24px 0;
      left: 110px;
      right: 0;
    }
  }

  &__title {
    margin: 0 16px;
    padding: 0 0 0 4px;
    position: sticky;
    left: 16px;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: $soySauce;

    @media screen and (min-width: $tablet) {
      display: none;
    }
  }
}

.agent-overview-table {
  width: 100%;
  padding-right: 16px;
  zoom: 100%; // remove
  display: table;
  border-spacing: 0px;

  @media screen and (min-width: $tablet) {
    padding: 0 16px 24px 0;
  }

  &__wrapper {
    padding: 24px 0 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (min-width: $tablet) {
      padding: 0;
    }
  }

  &__head {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: $feta;
  }

  &__cell-wrapper {
    &--agent-name {
      @media screen and (min-width: $tablet) {
        min-width: 360px;
        width: 360px;
      }
    }

    &--shops {
      min-width: 80px;
      width: 80px;

      @media screen and (min-width: $tablet) {
        min-width: 124px;
        width: 124px;
      }
    }
  }

  &__cell {
    height: 56px;
    min-width: 124px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $eel;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: $sourCream;

    font-size: 15px;
    font-weight: 500;
    line-height: 20.25px;
    color: $soySauce;

    .agent-overview-table__row:hover & {
      border-color: $thali01;
    }

    &--header {
      flex-direction: column;
      gap: 4px;
      background-color: $garlic;
      font-weight: 600;
      cursor: pointer;
      color: $truffle;
    }

    &--combined {
      max-width: 360px;
      width: 124px;
      padding: 0 16px;
      gap: 40px;
      flex-direction: row;

      @media screen and (min-width: $tablet) {
        width: auto;
        justify-content: space-between;
      }
    }

    &--shops {
      max-width: 80px;
      width: 80px;
      min-width: 80px;

      @media screen and (min-width: $tablet) {
        max-width: none;
        width: auto;
      }
    }

    &--expandable {
      align-items: flex-start;
    }

    &--devices {
      background-color: $clementine;
      color: $sourCream;
      border: 0;
    }

    &--orders_epos {
      background-color: $truffle;
      color: $sourCream;
      border: 0;
    }

    &--orders {
      background-color: $fmo;
      color: $sourCream;
      border: 0;
    }

    &--status {
      background-color: $blueberry;
      color: $sourCream;
      border: 0;
    }

    &--numbers {
      background-color: $purplePale;
      border: 0;
    }

    &--sms {
      background-color: $plum;
      color: $sourCream;
      border: 0;
    }

    &--marketing {
      background-color: $cremeBrulee;
      border: 0;
    }

    &--split {
      padding: 0 16px;
      justify-content: space-between;
    }

    &--success {
      background-color: $courgette;
      border-color: $kiwi;

      .agent-overview-table__row:hover & {
        border-color: $artichoke;
      }
    }

    &--success-dark {
      background-color: $rocket;
      border: 2px solid $kiwi;

      .agent-overview-table__row:hover & {
        border-color: $artichoke;
      }
    }

    &--warning {
      background-color: $bubblegum;
      border-color: $salmon;
      color: $fmo;

      .agent-overview-table__row:hover & {
        border-color: $fmo;
      }
    }

    &--warning-dark {
      background-color: $sorbet;
      border: 2px solid $salmon;
      color: $pepperoni;

      .agent-overview-table__row:hover & {
        border-color: $fmo;
      }
    }

    &--link {
      cursor: pointer;
    }
  }

  &__toggle-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &--expandable {
      justify-content: space-between;
    }
  }

  &__tag-wrapper {
    display: flex;
    gap: 4px;
  }

  &__caret-icon {
    width: 16px;
    height: 16px;
    margin-left: 16px;
    transform: rotate(180deg);

    &--open {
      transform: rotate(0deg);
    }
  }

  &__row {
    cursor: pointer;

    &--ex-staff {
      td:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 8px;
        background: repeating-linear-gradient(
          -45deg,
          $pancetta,
          $pancetta 10px,
          $sorbet 10px,
          $sorbet 20px
        );
        opacity: 0.2;
      }
    }
  }

  td, th {
    position: relative;
    padding: 0 $tableGutter $tableGutter 0;

    &:first-child {
      position: sticky;
      left: 0;
      padding-left: 16px;
      z-index: 1;
      background-color: $feta;

      @media screen and (min-width: $tablet) {
        padding-left: 0;
      }
    }

    &:nth-child(2) {
      background-color: $feta;

      @media screen and (min-width: $tablet) {
        position: sticky;
        left: 368px;
        z-index: 1;
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &__link {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &__region {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 144px;
  }
}