@import "../../styles/var";

.CreateShopCompleteModal {
    background-color: $sourCream;
    width: 600px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .header {
        position: relative;
        font-weight: 800;
        font-size: 26px;
        line-height: 1.875em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 40px;

        color: $soySauce;
    }

    .header::before {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        top: 11px;
        left: 0;
        background-color: $sorbet;
        border: 1px solid $fmoRed;
        border-radius: 100px;
    }

    .header::after {
        content: "";
        position: absolute;
        top: 17px;
        left: 9px;
        height: 7px;
        width: 3px;
        display: inline-block;
        border-top: 1px solid $fmoRed;
        border-left: 1px solid $fmoRed;
        transform: rotate(-135deg) !important;
        -webkit-tranform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
    }

    .fmoID {
        font-weight: 500;
        font-family: inherit;
        color: $thali02;
    }

    .shop-info {
        padding: 32px;
        background-color: $courgette;
        border: 1px solid $rocket;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 8px;


        :first-child {
            font-weight: 500;
            font-size: 20px;
            letter-spacing: 0.02em;
            line-height: 24px;
            text-transform: capitalize;
            color: $artichoke;
        }

        .shop-info-sub {
            font-weight: 500;
            font-size: 16px;
            color: $soySauce;
        }
    }

    hr {
        border: .5px solid $haddock;
        width: 100%;
    }

    .subtext {
        font-weight: 500;
        font-size: 16px;
        color: $soySauce;
        margin-bottom: 16px;
        padding-left: 4px;
    }

    .results {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        .results-item {
            border: 1px solid $haddock;
            border-radius: 5px;
            padding: 16px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;

            :first-child {
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
                display: flex;

                color: $truffle;
            }

            :last-child {
                font-weight: 500;
                font-size: 16px;
                color: $soySauce;
            }
        }

        .results-item:last-child {
            background-color: $garlic;
        }
    }

    .buttonGroup {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }

    .buttonGroup button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 5px !important;
        width: 50%;
        height: 46px;
        border-radius: 10px;

        font-weight: 600;
        font-size: 16px;

    }

    .buttonGroup button:first-child {
        color: $fmoRed;
        background: $sourCream;
        border: 1px solid $fmoRed;
    }

    .buttonGroup button:last-child {
        color: $fmoRed;
        background: $bubblegum;
        border: 1px solid $sorbet;
    }
}

#CreateShop {
    position: absolute;
    height: fit-content;
    background-color: $feta;
    top: 80px !important;
    left: 110px !important;
    right: 0;
    bottom: 0;
    padding: 24px 16px 32px 0;

    .CompleteModal {
        background-color: $sourCream;
        padding: 32px;
        width: 600px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 32px;


        .header {
            position: relative;
            font-weight: 800;
            font-size: 26px;
            line-height: 1.875em;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 40px;

            color: $soySauce;
        }

        .header::before {
            content: "";
            position: absolute;
            height: 20px;
            width: 20px;
            top: 11px;
            left: 0;
            background-color: $sorbet;
            border: 1px solid $fmoRed;
            border-radius: 100px;
        }

        .header::after {
            content: "";
            position: absolute;
            top: 17px;
            left: 9px;
            height: 7px;
            width: 3px;
            display: inline-block;
            border-top: 1px solid $fmoRed;
            border-left: 1px solid $fmoRed;
            transform: rotate(-135deg) !important;
            -webkit-tranform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
        }

        .fmoID {
            font-weight: 500;
            font-family: inherit;
            color: $thali02;
        }

        .shop-info {
            padding: 32px;
            background-color: $courgette;
            border: 1px solid $rocket;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            text-align: left;
            gap: 8px;


            :first-child {
                font-weight: 500;
                font-size: 20px;
                letter-spacing: 0.02em;
                line-height: 24px;
                text-transform: capitalize;
                color: $artichoke;
            }

            .shop-info-sub {
                font-weight: 500;
                font-size: 16px;
                color: $soySauce;
            }
        }

        hr {
            border: .5px solid $haddock;
            width: 100%;
        }

        .subtext {
            font-weight: 500;
            font-size: 16px;
            color: $soySauce;
            margin-bottom: 16px;
            padding-left: 4px;
        }

        .results {
            display: flex;
            justify-content: space-between;
            gap: 16px;

            .results-item {
                border: 1px solid $haddock;
                border-radius: 5px;
                padding: 16px;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 16px;

                :first-child {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 16px;
                    display: flex;

                    color: $truffle;
                }

                :last-child {
                    font-weight: 500;
                    font-size: 16px;
                    color: $soySauce;
                }
            }

            .results-item:last-child {
                background-color: $garlic;
            }
        }

        .buttonGroup {
            display: flex;
            flex-direction: row;
            gap: 16px;
        }

        .buttonGroup button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 5px !important;
            width: 50%;
            height: 46px;
            border-radius: 10px;

            font-weight: 600;
            font-size: 16px;

        }

        .buttonGroup button:first-child {
            color: $fmoRed;
            background: $sourCream;
            border: 1px solid $fmoRed;
        }

        .buttonGroup button:last-child {
            color: $fmoRed;
            background: $bubblegum;
            border: 1px solid $sorbet;
        }
    }

    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .sticky-tabs {
            width: 100%;
            position: sticky;
            top: 104px;
            z-index: 100;
            box-shadow: 0 0 0 16px $feta;
            background-color: $feta;
            display: flex;
            flex-direction: row;
            gap: 16px;

            div {
                width: 100%;
            }
        }

        .sticky-tabs::before {
            content: "";
            height: 32px;
            width: 100%;
            position: absolute;
            background-color: $feta;
            top: -32px;
        }

        .tab1,
        .tab2 {
            background: $sourCream;
            border: 1px solid $haddock;
            border-radius: 5px;
            height: 46px;
            font-weight: 400;
            font-size: 15px;
            line-height: 46px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;

            color: $thali02;
            cursor: pointer;
        }

        .tab1:hover,
        .tab2:hover {
            background: $garlic;
            transition: .2s ease-out;
            -webkit-transition: .2s ease-out;
            -moz-transition: .2s ease-out;
            -o-transition: .2s ease-out;
        }

        .activeTab {
            background: $soySauce !important;
            color: $sourCream !important;
            font-weight: 500;
            letter-spacing: 0.005em;
        }

        .nextButton,
        .nextButton2 {

            button {
                background: $haddock;
                width: 100%;
                height: 60px;
                border: none;
                border-radius: 5px;
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                text-align: left;
                padding: 0 32px;

                display: flex;
                justify-content: space-between;
                align-items: center;

                div {
                    color: $truffle;
                }
            }
        }

        .nextButton2 {
            button {
                background: $fmoRed;

                div {
                    color: $sourCream;
                }
            }
        }

        .buttonError {
            button {
                background: $bubblegum;
                border: 1px solid $sorbet;

                div {
                    color: $fmoRed;
                }
            }
        }

        .circle {
            background: $garlic;
            border: 1px solid $thali01;
            color: $truffle;

            height: 24px !important;
            width: 24px !important;
            border-radius: 100px;

            display: flex;
            justify-content: center;
            align-items: center;

            font-weight: bold;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.005em;
            margin-right: 16px;
        }

        .progress {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16px;
            background: $sourCream;
            border: 1px solid $haddock;
            border-radius: 5px;
            height: 46px;

            .text {
                flex-grow: 2;
                text-align: left;
                font-weight: 600;
                letter-spacing: 0.01em;
                font-size: 15px;

                color: $truffle;
                text-transform: capitalize;
            }

            .circleGroup {
                display: flex;
                flex-direction: row;
                gap: 8px;
                width: fit-content;

                .circle {
                    width: 24px !important;
                    height: 24px !important;
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 0 !important;
                }
            }

            img {
                width: 1.3em;
            }
        }

        .floatInput {

            position: relative;

            input {
                border: none;
                outline: 0;

                height: 46px;
                width: 100%;
                border: 1px solid $haddock;
                box-sizing: border-box;
                border-radius: 5px;

                padding: 8px 16px;

                font-size: 15px;
                font-weight: 500;
                color: $soySauce;
            }

            input::placeholder {
                color: $thali02;
                font-weight: 500;
            }

            input:focus {
                border-color: $sorbet;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active{
                -webkit-box-shadow: 0 0 0 30px $sourCream inset !important;
            }

            label {
                display: block;
                font-size: 15px;
                font-weight: 600;
                letter-spacing: 0.01em;
                letter-spacing: 0;
                margin-top: 16px;
                margin-bottom: 16px;
                color: $truffle;
            }

            .inputError {
                border: 1px solid $fmoRed !important;
            }

            .inputError::placeholder {
                color: $fmoRed;
            }
        }

        .detailsHeader {
            display: flex;
            align-items: center;

            :last-child {
                font-weight: 800;
                font-size: 26px;

                display: flex;
                align-items: center;

                color: $soySauce;
            }
        }

        .cc-active {
            border: 1px solid $sorbet !important;
            background: $bubblegum;
            position: relative;
            color: $fmoRed !important;
            font-weight: 500 !important;

            ::before {
                content: '';
                position: absolute;
                top: 12px;
                right: 16px;
                height: 20px;
                width: 20px;
                background-color: $sorbet;
                border: 1px solid $fmoRed;
                border-radius: 100px;
            }

            ::after {
                content: '';
                position: absolute;
                top: 18px;
                right: 25px;
                height: 7px;
                width: 3px;
                display: inline-block;
                border-top: 1px solid $fmoRed;
                border-left: 1px solid $fmoRed;
                transform: rotate(-135deg) !important;
                -webkit-tranform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
            }

            :nth-child(2),
            :last-child {
                color: $fmoRed !important;
                font-weight: 600 !important;
            }

            .ccPostcode {
                display: none !important;
                color: $fmoRed !important;
            }

        }

        .done {
            border: 1px solid $rocket;
            background: $courgette;
            color: $artichoke;
        }
        .error {
            background: $fmoRed;
            border: 1px solid $fmoRed;
            color: $sourCream;
            height: 24px !important;
            width: 24px !important;
        }

        .details {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            box-sizing: border-box;

            .section {
                background: $sourCream;
                border: 1px solid $haddock;
                border-radius: 5px;
                padding: 32px;

                display: flex;
                flex-direction: column;
                gap: 32px;

                -webkit-animation: opacity 0.2s ease-in-out;
                -moz-animation: opacity 0.2s ease-in-out;
                -o-animation: opacity 0.2s ease-in-out;
                animation: opacity 0.2s ease-in-out;
            }

            .inputGroup {
                display: flex;
                flex-direction: row;
                gap: 16px;
                width: 100%;

                .inputCol {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    width: 100%;
                }
            }

            .ccRow {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                column-gap: 16px;
                row-gap: 16px;
                padding: 0;
                margin: 0;

                .checkbox-cc {
                    width: calc(100/3);
                    display: flex;
                    border: 1px solid $haddock;
                    border-radius: 5px;
                    align-items: center;
                    height: 46px;
                    padding: 0 16px;
                    cursor: pointer;

                    .ccPostcode {
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 14px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        color: $thali02;
                        padding-right: 16px;
                    }

                    .ccPostcode:empty {
                        display: none;
                    }

                    :nth-child(2) {
                        font-weight: 400;
                        font-size: 15px;
                        display: flex;
                        align-items: center;
                        flex-grow: 1;                  
                    }
                }
            }

            .checkInputs {
                display: flex;
                gap: 16px;
                font-size: 15px !important;
                font-weight: 500;
                color: $truffle;
                cursor: pointer;

                div {
                    width: 25%;
                }

                .checkbox {
                    display: flex;

                    border: 1px solid $haddock;
                    border-radius: 5px;
                    align-items: center;
                    justify-content: flex-start;

                    height: 46px;
                    padding: 0 16px;
                    width: 20%;
                    font-size: 15px !important;

                    .circle {
                        background: white;
                        border: 1px solid $pink;
                        margin-right: 10px;
                        width: 1.2em;
                        height: 1.2em;
                    }
                    :last-child {
                        font-weight: 400;
                        line-height: 1.0625em;
                        display: flex;
                        align-items: center;

                        color: #424242;
                    }
                }

                .floatInput {
                    margin-right: 0;
                    flex-grow: 1;

                    input {
                        width: 100%;
                        margin-top: 0;
                        font-weight: 500;
                        color: #252525;

                        &::placeholder,
                        &::-moz-placeholder,
                        &::-webkit-input-placeholder {
                            color: #bbbbbb;
                            font-weight: normal;
                            font-size: 0.9em;
                        }
                    }
                }
            }

            .label {
                font-size: 15px;
                font-weight: 600;
                display: block;
                line-height: 20px;
                margin-bottom: 16px;
                margin-top: 0;
            }

            .choosePackage {
                display: flex;
                flex-direction: row;
                gap: 16px;
            }

            .packageOption {
                width: 100%;
                border: 1px solid $haddock;
                border-radius: 5px;
                height: 46px;
                display: flex;
                align-items: center;
                padding: 0 16px;
                gap: 8px;
                color: $thali01;
                cursor: pointer;
            }

            .packageActive {
                background-color: $bubblegum;
                border-color: $sorbet;
                color: $fmoRed;
                font-weight: 600;
                position: relative;
            }

            .packageActive::before {
                content: "";
                width: 20px;
                height: 20px;
                background-color: $sorbet;
                border: 1px solid $fmoRed;
                border-radius: 100px;
                position: absolute;
                top:12px;
                right: 16px;
            }

            .packageActive::after {
                content: "";
                position: absolute;
                top: 18px;
                right: 25px;
                height: 7px;
                width: 3px;
                display: inline-block;
                border-top: 1px solid $fmoRed;
                border-left: 1px solid $fmoRed;
                transform: rotate(-135deg) !important;
                -webkit-tranform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
            }

            .detailsTitle {
                margin-bottom: 16px;
                padding-left: 4px;
                font-size: 15px;
                font-weight: 600;
                display: flex;
                align-items: center;
                vertical-align: middle;
            }

            .labelRequirement {
                font-weight: 400;
                color: $thali02;
                margin-left: 4px;
            }



            .detailsTitle2 {
                justify-content: flex-end;
                &:before {
                    content: "*";
                    color: $fmoRed;
                    font-size: 1.75em;
                    padding-top: 5px;
                    margin-right: 4px;
                }
            }
        }
    }
}

/* media queries */
@media (max-width: 768px) {

    .Modal {
        border-radius: 0 !important;
        width: 100% !important;
        min-width: 100% !important;
        min-height: 100% !important;
        max-height: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
        overflow-x: none !important;
    }

    .CreateShopCompleteModal {
        width: 100% !important;
        height: 100% !important;
        box-sizing: border-box !important;

        .header {
            top: 0; 
            left: 0;
            background-color: transparent;
            align-items: center !important;
            height: 46px !important;
        }

        .shop-info {
            padding: 16px;

            .shop-info-sub {
                font-size: 15px;
            }
        }

        .subtext {
            line-height: 1.5;
            font-size: 15px;
            max-width: 90vw;
        }

        .results {
            flex-direction: column;
            gap: 8px;

            .results-item {
                width: 100%;
                box-sizing: border-box;

                div:first-child {
                    font-size: 14px !important;
                }

                div:nth-child(2) {
                    font-size: 15px;
                }
            }
        }

        .buttonGroup {
            flex-direction: column;

            button {
                width: 100%;

                &:first-child img {
                    display: none;
                }
            }
        }
    }

    #CreateShop {
        position: relative;
        width: 100vw !important;
        top: 62px !important;
        left: 0 !important;
        padding: 16px 16px 32px 16px;
        box-sizing: border-box;
    }
    
    //tab dropdown styling
    .overview-tabs {
        display: flex;
        flex-wrap: nowrap;
        gap: 16px;
        width: 100%;
    }
    
    .tabs-dropdown-wrapper {
        background-color: $feta;
        width: 100vw;
        height: fit-content;
        box-sizing: border-box;
        position: fixed;
        top: 80px;
        left: 0;
        z-index: 100;
        padding: 0 16px;
    }
    
    .tabs-dropdown-wrapper-active {
        background-color: $sourCream !important;
        border-bottom: 1px solid $haddock;
    }
    
    .tabs-dropdown {
        padding: 16px 0;

        -webkit-touch-callout: none; 
        -webkit-user-select: none; 
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; 
    
        .tabs-dropdown-button {
            height: 46px;
            width: 100%;
            background-color: $sourCream;
            border: 1px solid $haddock;
            border-radius: 5px;
            padding: 0 24px;
            box-sizing: border-box;
    
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            color: $truffle;
            font-size: 16px;
            font-weight: 600;
    
            img {
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
    
                -webkit-transition: 0.3s ease-out;
                -moz-transition: 0.3s ease-out;
                -o-transition: 0.3s ease-out;
                transition: 0.3s ease-out;
            }
    
            cursor: pointer;
        }
    
        .tabs-dropdown-button-active {
            background-color: $garlic !important;
            border-color: $thali01;
    
            img {
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(180deg);
    
                -webkit-transition: 0.3s ease-in;
                -moz-transition: 0.3s ease-in;
                -o-transition: 0.3s ease-in;
                transition: 0.3s ease-in;
            }
        }
    
        .dropdown-tabs-list {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin: 8px 0;
            background-color: none;
                        
            -webkit-animation: 0.3s ease-in-out;
            -moz-animation: 0.3s ease-in-out;
            -o-animation: 0.3s ease-in-out;
            animation: opacity 0.3s ease-in-out;

            -webkit-transition: 0.3s ease-in-out;
            -moz-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;
        }
    
        .overview-tab, 
        .overview-tab-active {
            // width: 100%;
            margin: 0;
        }
    }

    .details {
        padding: 80px 0 0 0;

        .section {
            padding: 24px !important;
            gap: 24px !important;
        }

        .inputGroup {
            flex-direction: column !important;
        }

        .choosePackage {
            flex-direction: column !important;

            .packageOption {
                width: 100% !important;
                box-sizing: border-box !important;
            }
        }

        .checkInputs {
            width: 100% !important;
            flex-wrap: wrap;

            .checkbox:nth-child(1), 
            .checkbox:nth-child(2), 
            .checkbox:nth-child(3), 
            .floatInput {
                width: 50% !important;
                flex-grow: 1 !important;
            }
        }

        .nextButton button, 
        .nextButton2 button {
            padding: 0 24px !important;
            height: 46px !important;
            font-size: 15px !important;
        }

        .buttonError {
            button {
                padding: 16px !important;
                gap: 16px !important;
                height: fit-content !important;
            }
        }
    }
    .ccRow {
        display: flex !important;
        flex-direction: column !important;
    }

}

.spinner-central {
    height: 5vw !important;
    text-align: center;
}



