@import "../../../styles/var";

.device-history {
  &__location-table {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 85px;
    gap: 8px;

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 100px;
    }
  }

  &__history-field-wrapper {
    min-height: 56px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid $haddock;
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
    color: $truffle;

    @media screen and (min-width: 768px) {
      padding: 16px;
    }

    &--header {
      background: $garlic;
      border-color: #E1DFDF;
      font-weight: 600;
      font-size: 16px;
    }

    &--date {
      align-items: center;
    }
  }

  &__history-field {
    display: flex;

    &--detail {
      font-weight: 400;
    }

    &--spacer {
      margin: 0 8px;
      color: $thali01;
    }

    &--id {
      font-weight: 400;
    }
  }

  &__note {
    margin-top: 16px;
    padding: 8px;
    background: $banoffee;
    border: 1px solid $butter;
    border-radius: 5px;

    @media screen and (min-width: 768px) {
      padding: 16px;
    }
  }

  &__note-header {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  &__note-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  &__empty-label {
    margin: 8px 0 0;
    font-weight: 500;
    font-size: 15px;
    color: $truffle;
  }
}