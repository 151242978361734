@import "../../styles/var.scss";

.applied-filter {
  background-color: $soySauce;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;

  color: $sourCream;
  font-size: 15px; 
  font-weight: 500;
  line-height: 20.25px;

  &--small {
    height: 32px;
    padding: 0 8px;

    font-size: 13px;
    line-height: 18.2px;
    letter-spacing: 0.01em;
  }

  &--medium {
    height: 40px;
    padding: 0 12px;
  }

  &--large {
    height: 48px;
    padding: 0 16px;
    gap: 8px;
    font-weight: 600;
  }

  &__close-icon {
    cursor: pointer;
  }
}