@import "../../styles/var";

.button-filter {
  height: 48px;
  border: 1px solid $eel;
  border-radius: $radius-mobile;
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 20.25px;
  color: $truffle;

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: $tablet) {
    height: 56px;
    border-radius: $radius;
    font-size: 16px;
    line-height: 20px;
  }

  &--active {
    background-color: $eel;
    font-weight: 600;
  }
}