@import "var.scss";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: opacity 0.2s ease-in-out;
}

.modal {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  overflow: hidden;
  background-color: $sourCream;

  -webkit-animation: opacity 0.2s ease-in-out;
  -moz-animation: opacity 0.2s ease-in-out;
  -o-animation: opacity 0.2s ease-in-out;
  animation: opacity 0.2s ease-in-out;

  @media (min-width: $tablet) {
    border-radius: 8px;
    width: 504px;
    height: 800px;
  }

  &--auto-height {
    @media (min-width: $tablet) {
      height: auto;
      max-height: 800px;
    }
  }

  &--view-interaction {
    @media screen and (min-width: $tablet) {
      margin: 0 24px;
      width: 896px !important;
      height: 600px;
      flex-direction: row;
    }
  }

  &__timeline {
    padding: 24px 24px 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;

    @media screen and (min-width: $tablet) {
      padding: 24px 32px 32px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;

    &--horizontal {
      flex-basis: '50%';
    }
  }

  &__submit-wrapper {
    padding: 24px 24px 34px;
    margin-top: auto;
    display: flex;
    gap: 16px;

    @media (min-width: $tablet) {
      padding: 24px 32px;
    }

    &--timeline {
      border-top: 1px solid $eel;

      @media (min-width: $tablet) {
        display: none;
      }
    }

    &--border {
      border-top: 1px solid $eel;
    }
  }
}

.modal-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    background-color: $haddock;
    border-left: 1px solid $eel;
    opacity: 0.75;
  }

  &::-webkit-scrollbar-thumb {
    width: 20px;
    background-color: $thali02;
    opacity: 0.75;
  }
}

.Modal {
    background: $sourCream;
    width: fit-content;
    height: fit-content;
    max-height: 700px;
    max-width: 750px;
    border-radius: 5px;
    border: none;
    overflow-y: auto;
    
    -webkit-animation: opacity 0.2s ease-in-out;
    -moz-animation: opacity 0.2s ease-in-out;
    -o-animation: opacity 0.2s ease-in-out;
    animation: opacity 0.2s ease-in-out;

    .modalContent {
        display: flex;
        flex-direction: column;
        padding: 32px;
        gap: 32px;
    }

    .modalStickyHeader {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 120;
        background-color: $sourCream;
        padding: 32px;
    }

    .catLabel {
        margin-top: 0;
    }

    .status-badge {
        margin-top: -56px !important;
    }

    .quickLinks .label {
        margin-top: 0;
    }

    .quickLinksButtons {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 16px;

        button {
            width: 100%;
        }

        button:disabled {
            background-color: $sourCream;
            color: $thali01 !important;
            border: 1px solid $haddock;
            cursor: not-allowed;
        }

        :first-child {
            background-color: $haddock;
            border: none;
            color: $truffle;
        }

        :last-child {
            background-color: $bubblegum;
            border: 1px solid $sorbet;
            color: $fmoRed;
        }
    }

    .scrollable {
        height: 636px;
        overflow: auto;
    }

    .purple {
        background: $purple !important;
    }

    .shift-up {
        margin-top: -20px;
    }

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modalTitle {
        font-weight: 700;
        font-size: 26px;
        line-height: 31px;
        color: $soySauce;
    }

    .modal-subtitle {
        margin-top: 20px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        text-transform: capitalize;

        color: #252525;
        margin-bottom: 10px;
    }

    .purple-active {
        background: $purplePale !important;
        color: $purple !important;
    }

    .badge {
        margin-top: -24px;
        margin-left: 0;
    }

    .badge:empty {
        display: none !important;
    }

    .input {
        margin-top: 16px;
        display: flex;
        flex-direction: column;

        div {
            display: flex;
            align-items: center;
            text-transform: capitalize;
        }

        textarea {
            border: 1px solid $haddock;
            border-radius: 5px;
            outline: none !important;
            box-shadow: none !important;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;

            font-size: 15px;
            font-weight: 450;
            line-height: 150%;
            padding: 16px;
            min-height: 250px;
            color: $soySauce;

            :focus {
                border-color: $sorbet !important;
            }

            &::placeholder {
                color: $thali01;
            }
        }

        button {
            height: 46px;
            margin-top: 32px;
            background: $fmo;
            border-radius: 5px;

            box-shadow: none;
            border: none;

            color: $sourCream;
            font-size: 15px;
            font-weight: 600;
        }
    }
}
