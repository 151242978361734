@import "../../../styles/var.scss";

#overview-page {
    position: absolute;
    background-color: $feta;
    height: 100vh;
    top: 80px !important;
    left: 110px !important;
    right: 0;
    bottom: 0;
    padding: 24px 16px 32px 0;

    .promo-label {
        margin-top: 0 !important;
        padding: 0;
    }
}

/* GENERAL STYLING */
.section-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    height: 40px;

    .icon {
        width: 40px;
        height: 40px;
        background-color: $garlic;
        border: 1px solid $haddock;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }

    h3 {
        color: $soySauce;
        font-weight: 700;
        flex-grow: 2;
        font-size: 18px;
        line-height: 24.3px;

        @media screen and (min-width: $tablet) {
          font-size: 20px !important;
          line-height: 26px;
        }
    }
}

.ov-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    @media (min-width: 1440px) {
        gap: 8px;
    }

    @media (min-width: 1600px) {
        gap: 16px;
    }

    min-height: 100px;
    padding: 16px;
    border-radius: 5px;
    text-align: center;

    -webkit-animation: opacity 0.2s ease-in-out;
    -moz-animation: opacity 0.2s ease-in-out;
    -o-animation: opacity 0.2s ease-in-out;
    animation: opacity 0.2s ease-in-out;

    div {
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 500;

        &:first-child {
            padding-top: 8px;
            letter-spacing: -0.005em;
        }
        
        &:nth-child(2) {
            font-size: 36px;
            font-weight: 900;
        }

        &:nth-child(3) {
            font-size: 10px;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: .02em;
            line-height: 20px;
        }
    }

}

.ov-stat-large {
    div:nth-child(2) {
        font-size: 50px !important;
    }
}

.ov-stat-grey {
    background-color: $garlic;
    
    div {
        color: $thali02;
    }
}

.ov-stat-green {
    background-color: $courgette;
    
    div {
        color: $truffle;

            &:last-child {
                color: $artichoke;
            }
        }
}

.ov-stat-red {
    background-color: $bubblegum;

    div {
        color: $fmoRed !important;
    }
}

.ov-stat-disabled {
    background-color: $sourCream;
    border: 1px solid $garlic;

    div {
        color: $thali01 !important;
    }
}

/* UTILITY CLASSES */
.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.overview-grid {
    background-color: $feta;

    .overview-content {
        display: flex;
        gap: 16px;
        margin-bottom: 32px;
    }

    .ov-section {
        background-color: $sourCream;
        border: 1px solid $haddock;
        border-radius: 5px;
        padding: 32px;
        width: 100%;
        box-sizing: border-box !important;
        height: fit-content;
        
        -webkit-animation: opacity 0.2s ease-in-out;
        -moz-animation: opacity 0.2s ease-in-out;
        -o-animation: opacity 0.2s ease-in-out;
        animation: opacity 0.2s ease-in-out;
    }

    .ov-info {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .ov-info-head-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media screen and (min-width: $tablet) {
                display: none;
            }

            .ov-info-head {
              display: flex;
              align-items: center;
              gap: 16px;
            }
        }

        .ov-name {
            margin-right: auto;
            font-weight: 900;
            font-size: 36px;
            color: $soySauce;
        }
            
        .ov-info-head-id {
            font-weight: 500;
            font-size: 26px;

            /* identical to box height */
            display: flex;
            align-items: flex-start;
            letter-spacing: -0.01em;
            color: $thali02;

            @media (min-width: 1600px) {
                align-items: center;
            }
        }

        .ov-apps {
          display: grid;
          gap: 16px;
          grid-template-columns: 1fr;

          @media screen and (min-width: 768px) {
            grid-template-columns: 216px 1fr;
          }
        }
            
        .ov-info-badges {
           flex-direction: row;
           gap: 8px;
           width: fit-content;

           .ov-info-badge-group {
            display: flex;
            flex-direction: row;
            gap: 8px;
            }

           a {
               text-decoration: none !important;
           }

           .ov-info-badge {
                width: fit-content;
                height: 46px;
                padding: 0 16px;
                border-radius: 5px;

                display: flex;
                align-items: center;
                gap: 8px;
                font-weight: 600;
                letter-spacing: 0.01em;
           }

           .ov-promo {
               border: 1px solid $haddock;
               background-color: $garlic;
               color: $truffle;
           }

           .ov-promo-missing {
               background-color: $sourCream;
               border: 1px solid $garlic;
               color: $thali01;
               opacity: 0.7 !important;
               font-weight: 500 !important;
               letter-spacing: 0.005em !important;
           }

           .ov-info-joined {
               background-color: $bubblegum;
               color: $fmoRed;
           }

           .ov-discount {
               background-color: $sorbet;
               color: $fmoRed;
           }

           .ov-no-discount {
               background-color: $sourCream !important;
               box-shadow: inset 0 0 0 1px $sorbet !important;
           }

            .ov-package {
                background-color: $macaroon;
                color: $purple;
            }

            .ov-package-free {
                background-color: $sourCream !important;
                box-shadow: inset 0 0 0 1px $purpleMiddle !important;
            }

            .ov-device {
              color: #F46B21;
              border: 1px solid $haddock !important;

              &--t2s {
                position: relative;
                top: -3px;
              }

              &--v2 {
                position: relative;
                bottom: -1px;
              }
            }

           .ov-apps-button-off,
           .ov-apps-button-on {
               display: flex;
               justify-content: center;
               align-items: center;
   
               width: fit-content;
               height: 46px;
               padding: 0 16px;
               border-radius: 5px;
   
               background: $sourCream;
               border: 1px solid $haddock !important;
               color: $thali01;
   
               img {
                   height: 24px;
                   margin-right: 8px;
               }
   
               div {
                   font-size: 15px;
                   display: flex;
                   align-items: center;
   
                   color: $thali01;
                   font-weight: 500;
                   letter-spacing: 0.005em;
               }
           }

           .ov-apps-button-on {
            background: $garlic;

                div {
                    color: $truffle !important;
                    font-weight: 600 !important;
                    letter-spacing: 0.01em;
                }
            }

        }

        .ov-info-main {
            display: grid;
            grid-template-columns: 200px 240px 2fr;
            column-gap: 32px;
            margin-top: 16px;

            .ov-info-img {
                position: relative;
                border: 1px solid $garlic;
                width: 200px;
                height: 200px;
                border-radius: 5px;

                img {
                    width: 200px;
                    height: 200px;
                    border-radius: 5px;
                }

                .ov-promo {
                    position: absolute;
                    bottom: 8px;
                    left: 8px;
                    height: 46px;
                    width: fit-content;
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;

                    background-color: $sourCream;
                    border: 1px solid $haddock;
                    border-radius: 5px;
                    padding: 0 16px;

                    img {
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            .ov-info-col {
                display: flex;
                gap: 16px;
                flex-direction: column;
                width: 100%;
                padding-top: 16px;
    
                .ov-info-text {
                    font-size: 15px;

                    :first-child {
                        font-weight: 600;
                        font-size: 15px;
                        letter-spacing: 0.01em;
                        color: $truffle;
                        display: flex;
                        align-items: center;
                        padding-bottom: 8px;
                    }

                    :last-child {
                        font-weight: 450;
                        color: $soySauce;
                    }
                }
            }
        }
    }
}

/* MACHINE SECTION */ 
.machine {
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    .machine-info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 2fr;
        column-gap: 16px;

        .machine-actions {
            display: flex;
            flex-direction: column;
            gap: 16px;
    
            color: $truffle;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.01em;

            .ov-buttons {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;
        
                button {
                    border: none;
                    border-radius: 5px;
                    box-shadow: none;
                    width: 100%;
                    height: 46px;
                    cursor: pointer;
        
                    font-weight: 600;
                    font-size: 15px;

                    &:first-child {
                        background-color: $fmoRed !important;
                        border: none !important;
                        color: $sourCream !important;
                    }

                    &:nth-child(2) {
                        background-color: $bubblegum !important;
                        border: 1px solid $sorbet !important;
                        color: $fmoRed !important;
                    }
                }
            }
        }

        .ov-login {
            display: flex;
            flex-direction: column;
            justify-content: center;

            background-color: $sourCream;
            border: 1px solid $haddock;
            border-radius: 5px;
            padding: 24px;

            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0.01em;

            :first-child {
                color: $truffle;
            }

            :last-child {
                margin-top: 16px;
                color: $soySauce;
                font-weight: 450;
            }
        }
        
        .ov-cc {
            background-color: $garlic !important;
        }

        .ov-machine-ping div:first-child {
            padding: 0;

            div:last-child {
                width: 300px; 
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

/* CUSTOMERS + ORDERS SECTION */

.wrapper1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
}

.ov-customers {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 402px; 

    .ov-customer-stats {
        display: flex;
        flex-direction: row;
        gap: 16px;
        flex-grow: 1;
    }

    .ov-stat-large {
        width: 100%;
    }
}

.ov-orders {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ov-orders-grid {
        width: 100%;
        display: grid; 
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
    }    
}


/* LAST ROW */
.wrapper2 {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    gap: 16px;

    .ov-apCard, 
    .ov-marketing {
        width: 50%;
        height: 100%;
    }
    
    .stats-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 16px;
        margin-top: 16px;

        .ov-stat {
            width: 50%;
        }
    } 
    .ccList {
        height: 40px;
        width: fit-content;
        padding: 0 16px;

        border: 1px solid $haddock;
        background-color: $sourCream;
        color: $soySauce;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
    }
}

/* CC MODAL */

.CCModal {
    max-width: 400px !important;
    display: flex;
    flex-direction: column;
    border: none;

    .badge {
        margin-top: -56px !important;
    }

    .CCs {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 32px;
    }

    .modalCard {
        background: $bubblegum;
        border: 1px solid $sorbet;
        border-radius: 5px;
        color: $truffle;
        padding: 24px 32px;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 8px;

        img {
            width: 24px;
            height: 24px;
            margin: 0 auto;
        }

        .modalCardName {
            color: $fmoRed;
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            letter-spacing: -0.005em;
        }

        .modalCardContent,
        .modalCardPhone {
            font-weight: 500;
            font-size: 15px;
            line-height: 135%;
            color: $truffle;
        }

        .modalCardPhone {
            font-weight: 600;
        }
    }
}


@media (min-width: 1600px) {
    .ov-stat {
        gap: 8px;

        div:nth-child(1) {
            font-size: 18px !important;
            letter-spacing: -0.005em;
        }

        div:nth-child(2) {
           font-size: 32px;
        }
    }
}

@media (max-width: 990px) and
(min-width: 768px) {

    .section, 
    .ov-section {
        padding: 24px !important;
    }

    .ov-info-main {
        grid-template-columns: 200px 1fr 2fr !important;
        gap: 16px !important;
        align-items: center;
    }

    .ov-name {
        font-size: 32px !important;
    }
    
    .ov-info-col {
        padding-top: 0 !important;
    }

    .machine-info {
        grid-template-columns: repeat(3, 1fr) !important;
        grid-template-rows: repeat(2, 1fr) !important;
        grid-gap: 16px;

        .ov-machine-ping {
            grid-column-start: 1;
            grid-column-end: span 3;
        }
    }

    .wrapper1 {
        display: flex;
        flex-direction: column;

        .ov-customers {
            min-height: fit-content !important;
        }
    }

    .wrapper2 {
        flex-direction: column !important;

        .ov-apCard, 
        .ov-marketing {
            width: 100%;
        }
    }

    .ov-stat {
        gap: 8px !important;

        div:first-child {
            font-size: 18px !important;
        }

        div:nth-child(2) {
            font-size: 26px !important;
        }
    }

}

@media (max-width: 768px) {

    .Modal {
        .CCModal {
            max-width: 100% !important;
            min-height: 100vh !important;
        }
    }
    
    #overview-page {
        width: 100vw !important;
        height: fit-content;
        position: relative;
        top: 159px !important;
        left: 0 !important;
        box-sizing: border-box;
        padding: 0 16px !important;
        
        .ov-section {
            padding: 24px !important;
        }

        .section-title h3 {
            // font-size: 24px !important;
        }

        .ov-info-head-wrapper {
            .ov-info-head {
                gap: 4px;
                flex-direction: column;
                align-items: flex-start;
            }

            .ov-name, 
            .ov-info-head-id {
                font-size: 20px !important;
            }
        }
        .ov-info-badges {
            display: grid !important;
            grid-auto-columns: max-content;
            grid-auto-rows: min-content;
            column-gap: 8px;
            row-gap: 8px;

            .ov-info-badge-group {
                display: flex;
                flex-direction: row;
                gap: 8px;
            }
        }

        .ov-info-main {
            display: flex !important;
            flex-direction: column;
            margin-top: 0 !important;

            .ov-info-text {
                margin-right: 16px !important;

                div:nth-child(2) {
                    line-height: 140%;
                }
            }
        }

        .machine-info {
            display: flex !important;
            flex-direction: column;
            gap: 16px;

            .machine-actions {
                .ov-buttons {
                    gap: 8px !important;
                }
            }

            .ov-machine-ping {
                div:nth-child(2) {
                    font-size: 26px !important;
                }
            }
        }

        .wrapper1 {
            display: flex;
            flex-direction: column;

            .ov-customers {
                min-height: fit-content !important;
            }

            .ov-customer-stats {
                gap: 8px;
            }

            .ov-stat-large {
                div:nth-child(2) {
                    font-size: 26px !important;
                }
            }
        }

        .ov-orders-grid {
            grid-template-columns: repeat(2, 1fr) !important;
            column-gap: 8px;
            row-gap: 8px;
        }

        .ov-stat {
            gap: 8px !important;
            padding:  16px;

            div:first-child {
                padding: 0 8px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                text-align: center;
                letter-spacing: -0.005em;
            }

            div:nth-child(2) {
                font-size: 26px;
                height: min-content;
            }
        }

        .wrapper2 {
            flex-direction: column !important;

            .ov-apCard, 
            .ov-marketing {
                width: 100% !important;
            }

            .ov-card-stats, 
            .ov-marketing-stats {
                gap: 8px !important;
                flex-direction: column !important;

                .ov-stat, 
                .ov-stat-grey, 
                .ov-stat-red {
                    width: auto !important;
                }
            }
        }
    }
}
