@import "../../../../styles/var";

.form-section {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__field-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    &--shop-info {
      @media screen and (min-width: $tablet) {
        grid-template-columns: repeat(2, 1fr);
      }
    
      @media screen and (min-width: $desktop) {
        grid-template-columns: repeat(4, 1fr);
      }

      & > :first-child {
        @media screen and (min-width: $tablet) {
          grid-column: 1 / 3;
        }

        @media screen and (min-width: $desktop) {
          grid-column: 1 / 5;
        }
      }
    }
  
    &--other-field {
      @media screen and (min-width: $tablet) {
        grid-template-columns: repeat(3, 1fr);
      }
  
      @media screen and (min-width: $desktop) {
        grid-template-columns: repeat(4, 1fr);
      }
  
      & > :last-child {
        @media screen and (min-width: $tablet) {
          grid-column: 1 / 4;
        }
  
        @media screen and (min-width: $desktop) {
          grid-column: 3 / 5;
        }
      }
    }
  }
}