@import "../../../styles/var";

.view-interaction-modal {
  &__image-wrapper {
    display: flex;
    background-color: black;
    justify-content: center;
    align-items: center;
    position: relative;

    @media screen and (min-width: $tablet) {
      flex-basis: 50%;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    max-height: 304px;
    flex-basis: 50%;
    object-fit: cover;
    background: black;

    @media screen and (min-width: $tablet) {
      max-height: none;
    }

    &--error {
      height: 200px;
    }

    &--landscape {
      object-fit: contain;
    }
  }

  &__attachments-tag-wrapper {
    position: absolute;
    top: 16px;
    left: 24px;

    @media screen and (min-width: $tablet) {
      top: 32px;
      left: 32px;
    }
  }

  &__info-wrapper {
    margin: 16px 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media screen and (min-width: $tablet) {
      margin: 16px 32px 32px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__description {
    margin: 0;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: $garlic;

    font-size: 15px;
    font-weight: 400;
    line-height: 21.75px;
    color: $truffle;
  }
}