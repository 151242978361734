@import "../../../../styles/var.scss";

.marketing {
  @media screen and (min-width: 768px) {
    padding: 0 16px;
  }

  @media screen and (min-width: 990px) {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 16px;
  }

  &__chart-wrapper {
    padding: 12px 0;
    margin-bottom: 12px;
    border-radius: 8px;
    border: 1px solid #979191;
    overflow: hidden;

    @media screen and (min-width: 990px) {
      margin: 0;
    }
  }

  &__data {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #979191;
  }

  &__data-item {
    padding-bottom: 8px;
    display: grid;
    grid-template-columns: 1fr 30px 30px;
    gap: 8px;
    align-items: center;
    text-align: end;
    font-size: 16px;
    color: $thali02;

    &:last-child {
      padding: 0;
    }

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 50px 50px;
      font-size: 18px;
    }
  }

  &__data-type {
    text-align: start;
    font-weight: 500;
  }
}