$fmoRed: #d82b31;
$purple: #5F09EB;
$plum: #8544F0;
$purplePale: #EDE3FC;
$purpleMiddle: #D2BCF6;
$macaroon: #F6F0FF;
$pink: #FDE2E2;
$gold: #EBBD2D;
$goldPale: #fcf5df;

$sourCream: #fff;
$garlic: #F7F7F7;
$haddock: #F0EFEF;
$feta: #fcfcfc;
$eel: #E1DFDF;
$thali01: #BDB7B7;
$thali02: #979191;
$truffle: #464141;
$soySauce: #302A2A;

$pepperoni: #CF262C;
$fmo: #D82B31;
$pancetta: #FBB7B6;
$salmon: #F6BCBC;
$sorbet: #FDE2E2;
$bubblegum: #FFF3F2;

$courgette: #F1F8ED;
$rocket: #DBECCF;
$pistachio: #BDE1B7;
$kiwi: #9BD392;
$artichoke: #43A564;

$banoffee: #FFF5E7;
$butter: #FFE9C8;
$cremeBrulee: #FFCF87;
$yolk: #FFA829;
$clementine: #F46B21;

$blueberry: #3D6DE8;
$stilton: #E5EDFF;
$starflower: #9EB8FA;

$text: #4D4C4C;
$darkText: #312D2D;

$desktop-large: 1728px;
$desktop: 1024px;
$tablet: 768px;

$radius-mobile: 4px;
$radius: 8px;

@import url("https://use.typekit.net/gsw2zrw.css");