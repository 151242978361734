@import "../../../styles/var";

#shop-checklist {
  position: absolute;
  background-color: $feta;
  height: 100vh !important;
  top: 80px !important;
  left: 110px !important;
  right: 0;
  bottom: 0;
  padding: 24px 4px 32px 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100% !important;
    box-sizing: border-box;
    height: 60px;

    .sticky-header {
      position: sticky !important;
      position: -webkit-sticky !important;
      display: flex;
      align-items: center;
      left: 110px;  
    }

    .results-filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 16px;
      margin-top: -8px;
      text-transform: capitalize;

      background: $soySauce;
      border-radius: 5px;
      padding: 16px;

      max-height: 60px;
      gap: 8px;

      :first-child {
        display: flex;

        :last-child {
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          color: $sourCream;
          letter-spacing: .01em;
        }
      }

      img {
        cursor: pointer;
        height: 24px;
        width: 24px;
      }
    }
  }

  table {
    border-collapse: unset !important;
    border-spacing: 8px !important;
    border: none !important;
    padding-bottom: 32px !important;
    position: absolute;
    top: 112px;
    margin-left: -7px !important;

    td, th {
      border-radius: 5px;
      padding: 0;
      text-align: center;
      vertical-align: middle;
      color: $truffle;
      box-shadow: 0 0 0 2px $feta;
      min-width: 70px;
      max-width: 300px;
      font-size: 15px !important;
    }

    td {
      border: 1px solid $haddock;
      background-color: #fff;

    }

    th {
      font-weight: 600;
      background: $feta;
    }

    tr {
      white-space: nowrap;
      background-color: $feta;
      position: relative;
    }

    .whiteText {
      color: $sourCream;
    }

    .redText {
      color: $fmoRed;
    }

    .greenText {
      color: $artichoke;
    }

    .purpleText {
      color: $purple;
    }

    .sticky-col {
      z-index: 110 !important;
    }

    .sticky-row::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 20px;
      background-color: $feta;
      outline: 7px solid $feta;
      left: 0;
      top: -28px;
      border-radius: 0 !important;
      z-index: 1 !important;
    }

    .sticky-row {
      position: sticky;
      position: -webkit-sticky;
      top: 114px;
      background-color: $garlic;
      border: 1px solid $haddock;
      outline: 8px solid $feta;
      max-width: 124px;

      @media (min-width: 1600px) {
        width: 124px;
      }

      img {
        shape-rendering: crispEdges !important;
      }

    }

    .status {
      &-green {
        background-color: $courgette;
        color: $truffle;
        border: 1px solid $rocket;

        img {
          padding-top: 4px;
        }

      }

      &-red {
        background-color: $bubblegum;
        color: $fmoRed;
        border: 1px solid $sorbet;

        img {
          padding-top: 4px;
        }
      }
    }

    .toggler {
      min-width: 60px;
      max-width: 60px;
      width: 60px;
      color: $darkText !important;
    }

    .wrapper {
      position: relative;
      white-space: nowrap;
    }

    .headcol {
      position: sticky;
      position: -webkit-sticky;
      min-width: 312px;
      max-width: 312px;
      width: 312px;
      left: 122px;
      outline: 8px solid $feta;
      min-height: 80px;
      max-height: 80px;
      height: 80px;
      text-align: left;

      &:nth-child(2) {
        left: 214px;
      }
    }

    .flex-basic {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .col-1 {
        flex: 1;
        .row-1 {
          margin-left: 16px;
          font-weight: 600;
          cursor: pointer;
        }

        .row-2 {
          margin-left: 16px;
          margin-top: 8px;
          font-weight: 500;
        }
      }

      .col-2 {
        flex: 0;
        margin-right: 16px;
        
        img {
          cursor: pointer;
        }
        
      }
    }

    .toggler-inner {
      height: 56px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .toggler-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }

      .toggler-right {
        flex-grow: 1;
        text-align: end;
        padding-top: 4px;
        padding-left: 8px;
      }
    }

    .hidden {
      display: none !important;
    }

    .promo_col {
      width: 124px !important;
      min-width: 124px;
      z-index: 100;

      img {
        margin: 0 auto;
      }

      img:nth-child(2) {
        margin: 0 8px;
      }
    }
  }
}

@media (max-width: 768px) {
  #shop-checklist {
    position: relative;
    height: fit-content;
    width: 100vw !important;
    top: 80px !important;
    left: 0 !important;
    padding: 16px 16px 32px 16px;
    box-sizing: border-box;
  }

  // .header {
  //   margin-bottom: 0;
  //   position: fixed;
  //   top: 80px;
  //   left: 0;
  //   padding-left: 16px;
  // }

  .results-filter {
    height: 46px !important;
    padding: 0 16px !important;
    position: fixed;
  }

  .results-filter:first-child {
    margin-left: 4px !important;
  }

  .sticky-header {
    left: 16px !important;

    .title {
      font-size: 32px;
      display: flex;
      align-items: center !important;
    }

    .results-filter {
      margin-top: 0 !important;
    }
  }

  .sticky-row::before {
    height: 16px !important; 
    top: -25px !important;
  }

  .headcol {
    left: 8px !important;
    max-width: 230px !important;
    min-width: 230px !important;
    width: 230px !important;

    .row-1 {
      max-width: 120px !important;
    }
  }

}
