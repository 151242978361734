@import "../../../styles/var";

#orders-history-wrapper {
    position: absolute;
    background-color: $feta;
    top: 80px !important;
    left: 0 !important;
    right: 0;
    bottom: 0;
    padding: 8px 16px 32px 0;
    display: flex;
    gap: 16px;
    flex-direction: column;
    height: fit-content;

    .section {
        background-color: $sourCream;
        border: 1px solid $haddock;
        border-radius: 5px;
        padding: 32px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 16px;
        
        -webkit-animation: opacity 0.2s ease-in-out;
        -moz-animation: opacity 0.2s ease-in-out;
        -o-animation: opacity 0.2s ease-in-out;
        animation: opacity 0.2s ease-in-out;
    }

    .last-twelve-months {
        display: flex;
        gap: 16px;
        flex-direction: column;
        
        .orders-calendar {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: 16px;
        }
    }

    .all-platforms {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 16px;

        @media screen and (max-width: 1200px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .header-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 16px;

        .title {
            flex-grow: 2;
        }

        .show-only {
            display: flex;
            gap: 8px;

            div {
                margin-right: 8px;
            }
        }

        button {
            height: 60px;
            padding: 0 16px;

            background-color: $sourCream;
            border: 1px solid $haddock;

            color: $truffle;
            font-weight: 500;
        }

        .selected {
            background-color: $bubblegum !important;
            border-color: $sorbet !important;
            color: $fmoRed !important;
            font-weight: 600;
        }
    }

    .table {
        border-collapse: unset !important;
        border-spacing: 8px !important;
        border: none !important;
        
        td,
        th {
            width: calc(100vw / 5);
            border-radius: 5px;
            height: 56px;
            max-height: 56px;
            padding: 0 16px;
            border: 1px solid $haddock;
            background-color: $sourCream;

            font-size: 15px;
            font-weight: 450;
            color: $truffle;
            vertical-align: middle !important;
            text-align: center;
        }

        th {
            color: $truffle;
            font-weight: 600;
            font-size: 15px;
            background: $garlic;
            border-color: $haddock;

            &:first-child {
                background-color: $sourCream !important;
                border: none !important;
            }

            input {
                height: 56px;
                width: 100%;
                border: 1px solid $haddock;
                box-sizing: border-box;
                border-radius: 5px;
                font-weight: 500;
                font-size: 15px;
                display: flex;
                align-items: center;
                padding-left: 48px;
                line-height: 56px;
                
                color: $soySauce;
            }

            input[type="text"]:focus {
                border-color: $sorbet !important;
            }

            input[type="text"]::placeholder {
                color: $thali01;
            }
        }

        .red td {
            background-color: $bubblegum;
            border-color: $sorbet;
        }

    }
}

@media (max-width: 990px) and
(min-width: 768px) {
    
    #job-history {
        padding: 24px !important;
    }
}

@media (max-width: 1200px) and
(min-width: 768px) {
    .orders-calendar {
        grid-template-columns: repeat(4, 1fr) !important;
    }
}


@media (max-width: 768px) {

    #orders-history-wrapper {
        height: fit-content !important;
        position: relative;
        padding: 0;
        padding-bottom: 32px;
        top: 0 !important;
        left: 0 !important;
    }

    .section {
        padding: 24px !important;
    }

    .orders-calendar {
        grid-template-columns: repeat(2, 1fr) !important;
        grid-gap: 8px !important;
    }

    .all-platforms {
        grid-template-columns: repeat(2, 1fr) !important;
        grid-gap: 8px !important;
    }

    .orders-history {
        @media screen and (orientation:portrait) {
            display: none !important;
        }

        @media screen and (orientation:landscape) {
          .table {
            display: block !important;
          }
        }
    }
}


