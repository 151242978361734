@import "../../styles/var";

#stats {
    background-color: #fcfcfc;
    top: 82px;
    left: 120px !important;
    right: 0;
    bottom: 0;
    position: absolute;
    padding-bottom: 20px;

    .date-range {
        display: flex;
        align-items: center;
        div {
            margin-right: 10px;
        }
        input, select {
            border-radius: 10px;
            border: 1px solid #eee;
            padding: 10px;
            margin-right: 10px;
            min-width: 350px;
        }
        .head {
            font-weight: 800;
            font-size: 15px;
            line-height: 18px;
            display: flex;
            align-items: center;
            letter-spacing: 0.03em;

            color: #424242;

            text-transform: uppercase;
        }

        .btn {
            background: $pink;
            color: $fmoRed;
            border-radius: 10px;
            padding: 10px;
            border: none;
        }
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .onlyShow {
            display: flex;

            div {
                border: 1px solid black;
                border-radius: 10px;
                padding: 10px;
                font-size: 14px;
                margin-right: 10px;
                text-align: center;
                cursor: pointer;
            }

            :first-child {
                border: none;
                text-align: right;
                margin: 0;
                cursor: default;
            }

            :nth-child(2),
            :nth-child(3),
            :nth-child(4) {
                border-color: $pink;
                color: $fmoRed;
            }

            .selected {
                border-color: $purplePale;
                color: $purple;
            }
        }
    }

    table {
        border-collapse: unset !important;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-spacing: 5px !important;
        border: none !important;

        td,
        th {
            border-radius: 10px;
            border-color: white !important;
            background: white;
        }

        thead {
            background: none !important;

            th {
                text-align: left;
            }
        }

        td {
            vertical-align: middle !important;
            color: #2f2621;
        }
        td.status {
            &-green {
                background-color: limegreen;
                color: white;
            }
            &-red {
                background-color: $fmoRed;
                color: white;
            }
        }
        th {
            color: #2f2621;
            font-weight: 600;
            font-size: 16px;
            line-height: 104%;
            vertical-align: bottom !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;

            border: none;
            background: white;
        }

        th.table_shop_info {
            background: $pink;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            height: 61px;
            min-height: 61px;
            max-height: 61px;
            color: $fmoRed;
        }
        th.table_numbers {
            background: $pink;
            height: 61px;
            min-height: 61px;
            max-height: 61px;
            color: $fmoRed;
        }
        th.table_orders {
            background: $purplePale;
            height: 61px;
            min-height: 61px;
            max-height: 61px;
            color: $purple;
        }
        th.table_sms {
            background: $purplePale;
            height: 61px;
            min-height: 61px;
            max-height: 61px;
            color: white;
        }
        tr {
            width: 1%;
            white-space: nowrap;
        }

        .redText {
            color: $fmoRed;
        }

        .purpleText {
            color: $purple;
        }

        .sticky-row {
            position: sticky;
            position: -webkit-sticky;
            top: 81px;

            z-index: 100;
        }

        .purple td {
            background-color: $purplePale;
        }

        .red td {
            background-color: $pink;
        }

        .gold td {
            background-color: $goldPale;
        }

        .white td {
            background-color: white;
        }

        .gray td {
            background-color: rgba(42, 42, 42, 0.1);
        }

        .promo_icon {
            margin-right: 4px;
        }
        .toggler {
            min-width: 150px;
            max-width: 150px;
            width: 150px;
            color: $darkText !important;
            background-position-x: -1px !important;
        }
        .wrapper {
            position: relative;
            white-space: nowrap;
            padding-right: 20px;
        }
        .headcol {
            position: sticky;
            position: -webkit-sticky;
            background-clip: padding-box;
            width: 120px;
            min-width: 100px;
            max-width: 100px;
            left: 120px;
        }

        .headcol2 {
            position: sticky;
            position: -webkit-sticky;
            background-clip: padding-box;
            left: 194px;
        }
        .flex-basic {
            display: flex;
            justify-content: space-between;
        }

        .toggler-inner {
            height: 60px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .toggler-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                img {
                    width: 20px;
                }
            }

            .toggler-right {
                flex-grow: 1;
                text-align: end;
                padding-bottom: 6px;
                align-self: flex-end;

            }
        }
        .hidden {
            display: none !important;
        }

        .promo_col {
            width: 100px;
            max-width: 100px;
            min-width: 100px;

            img {
                padding-right: 4px;
            }
        }
    }
}
