@import "../../styles/var";

.info-tag {
  display: flex;
  align-items: center;
  gap: 8px;

  &__icon {
    width: 16px;
    height: 16px;
  }

  &__created-label {
    font-size: 13px;
    font-weight: 600;
    line-height: 18.2px;
    letter-spacing: 0.02em;
    color: $text;
    white-space: nowrap;
  }
}