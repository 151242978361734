@import "../../styles/var";

#homeWrapper {
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    padding: 104px 16px 32px 110px;

    background-color: $feta;
    box-sizing: border-box !important;
    overflow: none !important;
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: 1fr 1fr;   
    column-gap: 16px;
    row-gap: 16px;
}

.box {
    background-color: $sourCream;
    border: 1px solid $haddock;
    border-radius: 5px;
    padding: 32px !important;
    height: auto;
}

.header-section {
    font-size: 26px;
    letter-spacing: -0.01em;
    font-weight: 700;
    color: $soySauce;
    display: flex;
    align-items: center;
    gap: 16px;
}

#myRegion {
    grid-row-start: 1;
    grid-row-end: span 3;
    grid-column-start: 1;
    grid-column-end: 1;
}

#regionStats {
    display: grid;
    grid-auto-columns: 1fr 1fr;
    grid-template-rows: 40px 1fr 1fr;
    grid-gap: 16px;
    height: 100%;
}

#regionStats .header-section {
    grid-column-start: 1;
    grid-column-end: span 2;
    justify-content: space-between;
}

#myPostcodes {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.postcode {
    background-color: $sourCream;
    border: 1px solid $haddock;
    border-radius: 5px;
    color: $truffle;
    font-size: 16px;
    font-weight: 600;
    padding: 0 8px;
    height: 40px;
    display: flex;
    align-items: center;
}

.stat {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 16px;
    font-weight: 800;
    font-size: 36px;

    -webkit-animation: opacity 0.2s ease-in-out;
    -moz-animation: opacity 0.2s ease-in-out;
    -o-animation: opacity 0.2s ease-in-out;
    animation: opacity 0.2s ease-in-out;
}

.statBadge {
    padding: 16px !important;
}

.stat span:nth-child(2) {
    // margin: 4px 0;
    height: 32px;
    display: flex;
    align-items: center;
}

.stat .label {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.005em;
    margin: 0;
    padding: 0;
}

.statGray, 
.statGray span {
    background-color: $garlic !important;
    color: $thali02 !important;
}

.statRed, 
.statRed span {
    background-color: $bubblegum;
    color: $fmoRed !important;
}

.statGreen, 
.statGreen span {
    background-color: $courgette;
    color: $artichoke;
}

.statGreen .label {
    color: $truffle !important;
}

#marketing {
    grid-row-start: 1;
    grid-row-end: span 2;
    grid-column-start: 2;
    grid-column-end: 2;
}

#marketingStats {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 40px 1fr;
    gap: 16px;
}

#marketingStats .header-section {
    grid-column-start: 1;
    grid-column-end: span 3;
}

#jobs {
    grid-row-start: 4;
    grid-row-end: span 2;
    grid-column-start: 1;
    grid-column-end: 1;
}

#jobStats {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 40px 1fr;
    gap: 16px;

    div:nth-child(2) {
        cursor: pointer;
    }
}

#jobStats .header-section {
    grid-column-start: 1;
    grid-column-end: span 3;
}

#orders {
    grid-row-start: 3;
    grid-row-end: span 3;
    grid-column-start: 2;
    grid-column-end: 2;
}

#orderStats {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 40px 1fr 1fr;
    gap: 16px;
}

#orderStats .header-section {
    grid-column-start: 1;
    grid-column-end: span 2;
}

#trend img {
    margin-left: 8px;
    margin-bottom: 4px;
}

/* media queries */
@media (min-width: 1600px) {
    .stat {
        font-size: 50px;
        gap: 16px;
    }

    .stat span:nth-child(2) {
        height: 40px;
    }

    #trend img {
        margin-bottom: 4px;
    }
}

@media (min-width: 1140px) and 
(max-width: 1600px) {
    .stat {
        font-size: 32px;
        padding: 8px;
        gap: 4px;
    
        .label {
            font-size: 16px !important;
            margin-top: 4px;
        }
             
        span:nth-child(2) {
            padding: 0;
        }
    }

}

@media (max-width: 1140px) {
    .box {
        padding: 24px !important;
    }

    #myPostcodes {
        display: none;
    }

    .stat {
        padding: 8px;
        display: flex; 
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;

        span:first-child {
            padding: 0 16px;
            font-size: 15px;
            font-weight: 500;
            text-align: center;
        }
        span:nth-child(2) {
            font-size: 26px;
            height: 26px;
            padding: 0;
            margin: 0;
        }
    }
}

@media (max-width: 768px) {
    #homeWrapper {
        height: fit-content;
        padding: 96px 16px 32px 16px;
        display: flex;
        flex-direction: column;
    }

    #regionStats {
        grid-template-rows: 88px 1fr 1fr; 
    }

    #regionStats .header-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
    }

    #myPostcodes {
        display: flex;
    }

    #regionStats,
    #marketingStats, 
    #jobStats, 
    #orderStats {
        row-gap: 8px;
        column-gap: 8px;
    }

    #marketingStats {
        grid-template-rows: 48px 1fr;
    }

    #jobStats {
        display: flex;
        flex-direction: column;
    }

    #orderStats {
        grid-template-rows: 48px 1fr 1fr;
    }

    .header-section {
        font-size: 24px;
        margin-bottom: 8px;
    }

    .stat {
        padding: 24px 16px;
    }
}

//fix stats spilling out 
@media (min-width: 1600px) and 
(max-height: 900px) {
    #homeWrapper {
        height: fit-content;
    }
}

@media (min-width: 1140px) and 
(max-height: 720px){
    #homeWrapper {
        height: fit-content;
    }
}

@media (max-width: 1140px) and 
(max-height: 648px) {
    #homeWrapper {
        height: fit-content;
    }
}