@import "../../styles/var";

.nav-bar-new {
  height: 80px;
  width: 100%;
  padding: 0 16px 0 32px;
  position: fixed;
  display: flex;
  gap: 40px;
  align-items: center;
  background-color: $sourCream;
  border-bottom: 1px solid $eel;
  z-index: 200;
  box-sizing: border-box;

  &__logo {
    height: 40px;
    width: 40px;
    cursor: pointer;
  }

  &__section {
    display: flex;
    align-items: center;
    gap: 16px;

    &--buttons {
      margin-left: auto;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: $soySauce;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;

    &--placeholder {
      font-weight: 500;
      letter-spacing: -0.01em;
      color: $thali02;
    }
  }
}