@import "../../styles/var";

html,
body {
    margin: 0;
    height: 100vh;
}

.loginPage {
    width: 100%;
    background-color: $bubblegum !important;
    background-position: center center !important;
    top: 0;
    left: 0 !important;
    right: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;
    height: 100vh;

    background-size: cover !important;

    .login-left {
        position: relative;
        background-color: $sourCream !important;
        background-size: cover !important;
        padding: 72px;
        height: 100vh;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 32px;

        @media (min-width: 1600px) {
            padding: 120px !important; 
        }

        .fmoLogo {
            height: 32px;
            width: 68px;
        }
        
        .login-content {
            display: flex;
            flex-direction: column;

            .top {
                display: flex;
                flex-direction: column;
                gap: 16px;

                img {
                    width: 60px;
                    height: 60px;
                }

                h2 {
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 120%;
                    color: $soySauce;
                    padding: 0;
                    margin: 0;
                }

                .message {
                    background-color: $garlic;
                    border: 1px solid $haddock;
                    border-radius: 5px;

                    padding: 0 16px;
                    height: 46px !important;
                    width: fit-content;
                    display: flex;
                    align-items: center;

                    color: $truffle;
                    font-size: 15px;
                }
            }
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 32px;
        }
    }

    input {
        background: $sourCream;
        border: 1px solid $haddock;
        box-sizing: border-box;
        border-radius: 5px;
        height: 46px;
        width: 100%;
        padding-left: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $soySauce;

        &:focus {
            border-color: $sorbet;
        }

        &::placeholder {
            font-weight: 400;
            color: $thali02;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $sourCream inset !important;
    }

    .inputError {
        border-color: $fmo !important;
        color: $fmo !important;
    }

    .inputError::placeholder {
        color: $fmo !important;
    }

    button {
        margin-top: 16px;
        height: 46px;
        width: 100%;
        background: $fmoRed;
        border-radius: 5px;
        font-size: 15px;
        text-align: center;
        box-shadow: none;
        border: none;
        color: $sourCream;
        justify-content: center;
        align-items: center;

        &:disabled {
            background-color: $pepperoni;
        }
    }
}

@media (min-width: 1200px) and 
(max-width: 1600px) {
    .login-left {
        padding: 72px !important;
    }
}

@media (min-width: 768px) and
(max-width: 1200px) {
    .login-left {
        padding: 40px !important;

        h2 {
            font-size: 26px !important;
        }
    }
}

@media (max-width: 768px) {
    .login-left {
        width: 100vw !important;
        padding: 56px !important;
        gap: 16px;
        justify-content: center !important;
        align-items: center !important;

        .fmoLogo {
            display: none;
        }

        .login-content {
            width: clamp(320px, 70vw, 80%);

            h2 {
                font-size: 26px !important;
                position: inherit;
                background-color: transparent !important;
                height: fit-content !important;
            }
        }
    }
}
